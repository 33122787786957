import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Switch, UploadProps } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Context } from "../../..";
import Modal from "../../../components/Modal";
import { ApiService } from "../../../core/api";
import { API_URL } from "../../../core/defaults";
import { useFetching } from "../../../core/hooks/useFetching";
import { isNullOrUndefined } from "../../../core/utils";

import styles from "../../../styles/Home.module.scss";

interface NFTItem {
  id?: number;
  name: string;
  url: string;
  image: string;
  buyPrice: string;
  count: string;
  currency?: string;
  status?: number;
}

const Portfolio: FC = () => {

  const { store } = useContext(Context);

  const [currentPage, setCurrentPage] = useState("nfts" as "nfts" | "offline" | "pool");

  const [NFTList, setNFTList] = useState([] as NFTItem[]);
  const [editedNFT, setEditedNFT] = useState(undefined as NFTItem | undefined);
  const [imagesArr, setImagesArr] = useState([] as string[]);
  const [editOrAdd, setEditOrAdd] = useState(0);
  const [isAddNFTVisible, setIsAddNFTVisible] = useState(false);
  const [isEditNFTVisible, setIsEditNFTVisible] = useState(false);
  const [isDeleteNFTVisible, setIsDeleteNFTVisible] = useState(false);

  const [editedOfflineP, setEditedOfflineP] = useState(undefined as NFTItem | undefined);
  const [imagesArrOP, setImagesArrOP] = useState([] as string[]);
  const [poolSizeEdit, setPoolSizeEdit] = useState("");
  const [poolSizeCurrentEdit, setPoolSizeCurrentEdit] = useState("");
  const [editOrAddOP, setEditOrAddOP] = useState(0);
  const [isAddOPVisible, setIsAddOPVisible] = useState(false);
  const [isEditOPVisible, setIsEditOPVisible] = useState(false);
  const [isDeleteOPVisible, setIsDeleteOPVisible] = useState(false);
  const [offlineList, setOfflineList] = useState([] as any[]);
  
  const props2: UploadProps = {
    name: 'image', multiple: false, maxCount: 1, showUploadList: false,
    headers: {"Authorization": "Bearer " + store.user.token},
    action: `${API_URL}/mbc/image/upload/`,
    onDrop(e) {console.log('Dropped files', e.dataTransfer.files);},
  };

  const saveOP = () => {
    if (editOrAddOP == 0) {
      setIsEditOPVisible(true);
    } else {
      setIsAddOPVisible(true);
    }
  }

  const saveOPAction = async () => {
    console.log(editedOfflineP);
    console.log(editOrAddOP);

    if (editOrAddOP == 0) {
      setIsEditOPVisible(false);
      const cr = await ApiService.mbc.updateOffProj(editedOfflineP?.id!, editedOfflineP);
      fetchProjects();
    } else {
      setIsAddOPVisible(false);
      setEditedOfflineP(undefined);
      const cr = await ApiService.mbc.addOffProj(editedOfflineP);
      fetchProjects();
    }

    toast("Данные проекта успешно обновлены!", {type: 'success'});
  }

  const saveNFT = () => {
    if (editOrAdd == 0) {
      setIsEditNFTVisible(true);
    } else {
      setIsAddNFTVisible(true);
    }
  }

  const saveNFTAction = async () => {
    console.log(editedNFT);
    console.log(editOrAdd);

    if (editOrAdd == 0) {
      setIsEditNFTVisible(false);
      const cr = await ApiService.mbc.updateNft(editedNFT?.id!, editedNFT);
      fetchNfts();
    } else {
      setIsAddNFTVisible(false);
      setEditedNFT(undefined);
      const cr = await ApiService.mbc.addNft(editedNFT);
      fetchNfts();
    }

    toast("Данные NFT успешно обновлены!", {type: 'success'});
  }

  const deleteNFT = () => {
    setIsDeleteNFTVisible(true);
  }

  const deleteNFTAction = async () => {
    setIsDeleteNFTVisible(false);
    toast(`Данные NFT ${editedNFT!.name} успешно удалены!`, {type: 'success'});
    setEditedNFT(undefined);
    const del = await ApiService.mbc.deleteNft(editedNFT!.id!);
    console.log(del);
    fetchNfts();
  }

  const deleteOP = () => {
    setIsDeleteOPVisible(true);
  }

  const deleteOPAction = async () => {
    setIsDeleteOPVisible(false);
    toast(`Данные проекта ${editedOfflineP!.name} успешно удалены!`, {type: 'success'});
    setEditedOfflineP(undefined);
    const del = await ApiService.mbc.deleteOffProj(editedOfflineP!.id!);
    console.log(del);
    fetchProjects();
  }

  const closeForm = () => {
    setEditedNFT(undefined);
  }

  const closeFormOP = () => {
    setEditedOfflineP(undefined);
  }

  const addNewOP = () => {
    setEditedOfflineP({
      name: "",
      url: "",
      status: 1,
      count: "1",
      image: "",
      buyPrice: "",
      currency: "SOL"
    });
    setImagesArrOP([""]);
    setEditOrAddOP(1);
  }

  const addNewNFT = () => {
    setEditedNFT({
      name: "",
      url: "",
      status: 1,
      count: "1",
      image: "",
      buyPrice: "",
      currency: "SOL"
    });
    setEditOrAdd(1);
  }

  const savePool = async () => {
    const res = await ApiService.mbc.setPoolSize({size: poolSizeEdit, current: poolSizeCurrentEdit});
    toast("Данные успешно обновлены!", {type: "success"});
  }

  const [fetchNfts, isNftsLoading, errorNfts] = useFetching(async () => {
    const list = await ApiService.mbc.getNfts();
    const dataList: NFTItem[] = list.data;
    dataList.sort((a, b) => {
      if (a.id! > b.id!) {
        return -1;
      } else if (a.id! < b.id!) {
        return 1;
      } else {
        return 0;
      }
    });
    setNFTList(dataList);
  });

  const [fetchProjects] = useFetching(async () => {
    const list = await ApiService.mbc.getOffProjs();
    setPoolSizeEdit(list.data.pool);
    setPoolSizeCurrentEdit(list.data.currentPool);
    const dataList: any[] = list.data.list;
    dataList.sort((a, b) => {
      if (a.id! > b.id!) {
        return -1;
      } else if (a.id! < b.id!) {
        return 1;
      } else {
        return 0;
      }
    });
    setOfflineList(dataList);
  });

  const clearCache = async () => {
    const data = await ApiService.mbc.clearCache();
    toast("Кеш на сайте успешно сброшен и обновлен!", {type: "success"});
  }

  const updateCount = async (value: string) => {
    setEditedNFT({...editedNFT!, count: value})
    let arr = [];
    let imgs = editedNFT!.image.split(";");
    for (let i = 0; i < parseInt(value); i++) {
      if (!isNullOrUndefined(imgs[i])) {
        arr.push(imgs[i]);
      } else {
        arr.push("");
      }
    }
    setImagesArr(arr);
  }

  const updateCountOP = async (value: string) => {
    setEditedOfflineP({...editedOfflineP!, count: value})
    let arr = [];
    let imgs = editedOfflineP!.image.split(";");
    for (let i = 0; i < parseInt(value); i++) {
      if (!isNullOrUndefined(imgs[i])) {
        arr.push(imgs[i]);
      } else {
        arr.push("");
      }
    }
    setImagesArrOP(arr);
  }

  const generateImages = (item: NFTItem) => {
    let arr: string[] = [];

    let imgs = item.image.split(";");
    for (let i = 0; i < parseInt(item.count); i++) {
      if (!isNullOrUndefined(imgs[i])) {
        arr.push(imgs[i]);
      } else {
        arr.push("");
      }
    }

    return arr;
  }

  useEffect(() => {
    fetchNfts();
    fetchProjects();
  }, []);

  return (
    <>
    <div className="row">
        <div className="col-12 col-md-12">
          <h1 className="page-header">
            NFT <small>Портфель</small>
          </h1>

          <div className="card mb-4">
            <ul className="nav nav-tabs nav-tabs-v2 px-4">
              <li className="nav-item me-3"><a href="#allTab" className={currentPage == "nfts" ? "nav-link px-2 active" : "nav-link px-2"} data-bs-toggle="tab" onClick={() => setCurrentPage("nfts")}>NFT</a></li>
              <li className="nav-item me-3"><a href="#publishedTab" className={currentPage == "offline" ? "nav-link px-2 active" : "nav-link px-2"} data-bs-toggle="tab" onClick={() => setCurrentPage("offline")}>Offline Проекты</a></li>
              <li className="nav-item me-3"><a href="#publishedTab1" className={currentPage == "pool" ? "nav-link px-2 active" : "nav-link px-2"} data-bs-toggle="tab" onClick={() => setCurrentPage("pool")}>CryptoPool</a></li>
            </ul>

            {currentPage == "pool" ? <div className="p-4">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group mb-2">
                    <label className="form-label">Сумма покупки</label>
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Сумма покупки" value={poolSizeEdit} onChange={(event) => setPoolSizeEdit(event.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group mb-2">
                    <label className="form-label">Текущая оценка</label>
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Текущая оценка" value={poolSizeCurrentEdit} onChange={(event) => setPoolSizeCurrentEdit(event.target.value)} />
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-12" style={{
                  paddingTop: "12px"
                }}>
                  <button className="topracing-btn topracing-btn__big topracing-btn-red" onClick={() => savePool()}>Сохранить</button>
                </div>
              </div>
            </div> : <></>}

            {currentPage == "nfts" ? <div className="p-4">
              <h5 className="card__title">Список NFT 
                <span className="ct_action" style={{right: "190px"}} onClick={() => addNewNFT()}><PlusOutlined /> <b style={{marginLeft: "6px", fontWeight: "500"}}>Добавить</b></span>
                <span className="ct_action" onClick={() => clearCache()}><DeleteOutlined /> <b style={{marginLeft: "6px", fontWeight: "500"}}>Сбросить кеш</b></span>
              </h5>

              <div className={styles.nft_edit}>
                {editedNFT == undefined ? <h4>Выберите NFT из списка или добавьте новую...</h4> : <>
                  
                  <div className="row">
                    <div className="col-12 col-md-12" style={{marginBottom: "18px"}}>
                      <div className={styles.images_ups}>
                        {imagesArr.map((item, i, a) => <div className={styles.imups}><Dragger {...props2} onChange={(info) => {
                          const { status } = info.file;
                          if (status === 'done') {
                            // info.file.response.urls[0]
                            console.log(info.file.response.save.split("data/www/")[1])
                            console.log(editedNFT);

                            let temp = [...imagesArr];
                            temp[i] = "https://" + info.file.response.save.split("data/www/")[1];
                            setImagesArr(temp);

                            let imgstr = "";
                            temp.forEach(item => {
                              if (imgstr == "") {
                                imgstr += item;
                              } else {
                                imgstr += ";" + item;
                              }
                            })

                            setEditedNFT({...editedNFT!, image: imgstr})
                            
                            toast("Изображение успешно загружено!", {type: 'success'});
                            console.log(`${info.file.name} file uploaded successfully.`);
                          }
                        }}>
                          <p className="ant-upload-drag-icon" style={{marginBottom: "10px"}}>
                            <img src={item} style={{width: "70px", borderRadius: "10px"}} />
                          </p>
                          <p className="ant-upload-text">Картинка NFT</p>
                          <p className="ant-upload-hint">
                            нажмите или перенесите изображение в эту область
                          </p>
                        </Dragger></div>)}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group mb-2">
                        <label className="form-label">Наименование NFT</label>
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="Metabuilders" value={editedNFT.name} onChange={(event) => setEditedNFT({...editedNFT, name: event.target.value})} />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group mb-2">
                        <label className="form-label">Ссылка на MagicEden</label>
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="URL MagicEden" value={editedNFT.url} onChange={(event) => setEditedNFT({...editedNFT, url: event.target.value})} />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group mb-2">
                        <label className="form-label">Сумма закупки</label>
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="1.0" value={editedNFT.buyPrice} onChange={(event) => setEditedNFT({...editedNFT, buyPrice: event.target.value})} />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group mb-2">
                        <label className="form-label">Кол-во закупки</label>
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="1" value={editedNFT.count} onChange={(event) => updateCount(event.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group mb-2">
                        <label className="form-label">Статус</label>
                        <div className="">
                          <Switch checked={editedNFT.status == 1} onChange={(event) => setEditedNFT({...editedNFT, status: event ? 1 : 0})} /> <span>{editedNFT.status == 1 ? "Вкл" : "Выкл"}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-12" style={{
                      paddingTop: "12px"
                    }}>
                      <button className="topracing-btn topracing-btn__big topracing-btn-red" onClick={() => saveNFT()}>Сохранить</button>
                      {editOrAdd == 0 ? <button style={{marginLeft: "8px", marginRight: "8px"}} className="topracing-btn topracing-btn__big" onClick={() => deleteNFT()}>Удалить</button> : <></>}
                      <button className="topracing-btn topracing-btn-clear" onClick={() => closeForm()}>Закрыть</button>
                    </div>
                  </div>

                </>}
              </div>

              <div className={styles.nft_list}>
                {NFTList.map(item => <div className={styles.nft_list__item} onClick={() => {setEditedNFT(item); setImagesArr(generateImages(item)); setEditOrAdd(0);}}>
                  <div className={styles.nft_list__item_status} style={{backgroundColor: item.status == 1 ? "green" : "red"}}></div>
                  <div className={styles.nft_list__item_title}>{item.name} <small>#{item.id}</small></div>
                  <div className={styles.nft_list__item_price}>BP: <b>{item.buyPrice}</b> <small>{item.currency}</small></div>
                  <div className={styles.nft_list__item_url}><a href={item.url} target="_blank">{item.url}</a></div>
                </div>)}
              </div>
            </div> : <></>}

            {currentPage == "offline" ? <div className="p-4">
              <h5 className="card__title">Список Offline проектов 
                <span className="ct_action" style={{right: "190px"}} onClick={() => addNewOP()}><PlusOutlined /> <b style={{marginLeft: "6px", fontWeight: "500"}}>Добавить</b></span>
                <span className="ct_action" onClick={() => clearCache()}><DeleteOutlined /> <b style={{marginLeft: "6px", fontWeight: "500"}}>Сбросить кеш</b></span>
              </h5>

              <div className={styles.nft_edit}>
                {editedOfflineP == undefined ? <h4>Выберите offline проект из списка или добавьте новый...</h4> : <>
                  
                  <div className="row">
                    <div className="col-12 col-md-12" style={{marginBottom: "18px"}}>
                      <div className={styles.images_ups}>
                        {imagesArrOP.map((item, i, a) => <div className={styles.imups}><Dragger {...props2} onChange={(info) => {
                          const { status } = info.file;
                          if (status === 'done') {
                            // info.file.response.urls[0]
                            console.log(info.file.response.save.split("data/www/")[1])
                            console.log(editedNFT);

                            let temp = [...imagesArrOP];
                            temp[i] = "https://" + info.file.response.save.split("data/www/")[1];
                            setImagesArrOP(temp);

                            let imgstr = "";
                            temp.forEach(item => {
                              if (imgstr == "") {
                                imgstr += item;
                              } else {
                                imgstr += ";" + item;
                              }
                            })

                            setEditedOfflineP({...editedNFT!, image: imgstr})
                            
                            toast("Изображение успешно загружено!", {type: 'success'});
                            console.log(`${info.file.name} file uploaded successfully.`);
                          }
                        }}>
                          <p className="ant-upload-drag-icon" style={{marginBottom: "10px"}}>
                            <img src={item} style={{width: "70px", borderRadius: "10px"}} />
                          </p>
                          <p className="ant-upload-text">Лого проекта</p>
                          <p className="ant-upload-hint">
                            нажмите или перенесите изображение в эту область
                          </p>
                        </Dragger></div>)}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group mb-2">
                        <label className="form-label">Наименование проекта</label>
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="Metabuilders" value={editedOfflineP.name} onChange={(event) => setEditedOfflineP({...editedOfflineP, name: event.target.value})} />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group mb-2">
                        <label className="form-label">Ссылка на сайт проекта</label>
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="URL проекта" value={editedOfflineP.url} onChange={(event) => setEditedOfflineP({...editedOfflineP, url: event.target.value})} />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group mb-2">
                        <label className="form-label">Текущая оценка</label>
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="1.0" value={editedOfflineP.buyPrice} onChange={(event) => setEditedOfflineP({...editedOfflineP, buyPrice: event.target.value})} />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group mb-2">
                        <label className="form-label">Кол-во закупки</label>
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="1" value={editedOfflineP.count} onChange={(event) => updateCountOP(event.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group mb-2">
                        <label className="form-label">Статус</label>
                        <div className="">
                          <Switch checked={editedOfflineP.status == 1} onChange={(event) => setEditedOfflineP({...editedOfflineP, status: event ? 1 : 0})} /> <span>{editedOfflineP.status == 1 ? "Вкл" : "Выкл"}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-12" style={{
                      paddingTop: "12px"
                    }}>
                      <button className="topracing-btn topracing-btn__big topracing-btn-red" onClick={() => saveOP()}>Сохранить</button>
                      {editOrAddOP == 0 ? <button style={{marginLeft: "8px", marginRight: "8px"}} className="topracing-btn topracing-btn__big" onClick={() => deleteOP()}>Удалить</button> : <></>}
                      <button className="topracing-btn topracing-btn-clear" onClick={() => closeFormOP()}>Закрыть</button>
                    </div>
                  </div>

                </>}
              </div>

              <div className={styles.nft_list}>
                {offlineList.map(item => <div className={styles.nft_list__item} onClick={() => {setEditedOfflineP(item); setImagesArrOP([item.image]); setEditOrAddOP(0);}}>
                  <div className={styles.nft_list__item_status} style={{backgroundColor: item.status == 1 ? "green" : "red"}}></div>
                  <div className={styles.nft_list__item_title}>{item.name} <small>#{item.id}</small></div>
                  <div className={styles.nft_list__item_price}>Цена: <b>{item.buyPrice}</b></div>
                  <div className={styles.nft_list__item_url}><a href={item.url} target="_blank">{item.url}</a></div>
                </div>)}
              </div>

            </div> : <></>}
          </div>
        </div>
      </div>


      <Modal
        visible={isAddOPVisible}
        title="Добавить проект"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите добавить проект{editedOfflineP != undefined ? ` "${editedOfflineP!.name}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => saveOPAction()}>Добавить</button>
          </div>
        </>
        }
        onClose={() => {setIsAddOPVisible(false)}}/>

      <Modal
        visible={isAddNFTVisible}
        title="Добавить NFT"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите добавить NFT{editedNFT != undefined ? ` "${editedNFT!.name}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => saveNFTAction()}>Добавить</button>
          </div>
        </>
        }
        onClose={() => {setIsAddNFTVisible(false)}}/>

      <Modal
        visible={isEditOPVisible}
        title="Обновить NFT"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите обновить проект{editedOfflineP != undefined ? ` "${editedOfflineP!.name}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => saveOPAction()}>Обновить</button>
          </div>
        </>
        }
        onClose={() => {setIsEditOPVisible(false)}}/>

      <Modal
        visible={isEditNFTVisible}
        title="Обновить NFT"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите обновить NFT{editedNFT != undefined ? ` "${editedNFT!.name}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => saveNFTAction()}>Обновить</button>
          </div>
        </>
        }
        onClose={() => {setIsEditNFTVisible(false)}}/>

      <Modal
        visible={isDeleteNFTVisible}
        title="Удалить NFT"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите удалить NFT{editedNFT != undefined ? ` "${editedNFT!.name}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => deleteNFTAction()}>Удалить</button>
          </div>
        </>
        }
        onClose={() => {setIsDeleteNFTVisible(false)}}/>

      <Modal
        visible={isDeleteOPVisible}
        title="Удалить проект"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите удалить проект{editedOfflineP != undefined ? ` "${editedOfflineP!.name}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => deleteOPAction()}>Удалить</button>
          </div>
        </>
        }
        onClose={() => {setIsDeleteOPVisible(false)}}/>

    </>
  )
}

export default observer(Portfolio)