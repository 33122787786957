import { FC } from "react";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";

import styles from '../styles/MenuLink.module.scss';

interface MenuLinkProps {
  to: string;
  icon: string;
  children: any;
}

const MenuLink: FC<MenuLinkProps> = (props) => {
  const resolved = useResolvedPath(props.to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const navigate = useNavigate();

  console.log(props.to, match);

  return (
    <div className={match != null ? "menu-item active" : "menu-item"}>
      <a onClick={() => navigate(props.to)} className="menu-link">
        <span className="menu-icon"><i className={props.icon}></i></span>
        <span className="menu-text">{props.children}</span>
      </a>
    </div>
  )
}

export default MenuLink