import { FC, useContext, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Context } from "../../..";

import styles from "../../../styles/RumbleBattle.module.scss";
import { useFetching } from "../../../core/hooks/useFetching";
import { ApiService } from "../../../core/api";
import { DebounceInput } from "react-debounce-input";
import XTable, { XTableCol } from "../../../components/XTable";
import { isNullOrUndefined } from "../../../core/utils";

interface StatsData {
  allgames: number;
  wingames: number;
  waiting: number;
  infight: number;
  declined: number;
  allsum: number;
  procsum: number;
  usersum: number;
}

type SearchType = "" | "ended" | "fight" | "declined" | "waiting";

const RumbleBattle: FC = () => {

  const { store } = useContext(Context);
  
  const [stats, setStats] = useState(undefined as undefined | StatsData);
  const [top, setTop] = useState([] as any[]);
  
  const [searchText, setSearchText] = useState(""); // Wallet / Name
  const [searchType, setSearchType] = useState("" as SearchType);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalGames, setTotalGames] = useState(0);
  const [gamesList, setGamesList] = useState([] as any[]);
  
  const [gamesTable, setGamesTable] = useState([] as XTableCol[][]); // MAIN / 
  const gamesThs: string[] = ["ID", "Бойцы", "Раунды", "На кону", "Статус", "Действия"];
  
  const [fetchStats] = useFetching(async () => {
    const resTop = await ApiService.games.getTopWinners();
    const res = await ApiService.games.getGamesStats();

    setStats(res.data.stats);
    setTop(resTop.data.top);
  });

  const [fetchGames, isGamesLoading] = useFetching(async () => {
    const res = await ApiService.games.getGames({
      userId: 0,
      status: 9,
      limit: 10,
      page: pageIndex,
      search: searchText,
      filter: searchType
    });
    
    console.log(res);

    setTotalGames(res.data.total_games);
    setTotalPages(res.data.total_pages - 1);

    let lst: XTableCol[][] = [];
    res.data.games.forEach((game: any) => {
      let usrs: any[] = [...game.users];
      if (game.status == 2 && game.status_accepting == 1) {
        usrs.forEach((user: any, i: number) => {
          console.log(game.winner_id, user.id);
          usrs[i].winner = game.winner_id == user.id;
        });
      }

      lst.push([
        {text: game.id},
        {
          isUsers: true,
          users: usrs
        },
        {text: game.round_count},
        {text: game.sol_price},
        {isBadge: true, badgeState: game.status_accepting == 2 ? false : true, text: game.status_accepting == 2 ? "Отклонен" : (game.status == 2 && game.status_accepting == 1 ? "Завершен" : (game.status_accepting == 0 ? "Ожидание" : (game.status == 1 && game.status_accepting == 1 ? "В бою" : "--")))},
        {showActions: true, showActionsEdit: false, showActionsTrash: true, trashAction: () => {
          
        }}
      ]);
    });

    setGamesTable(lst);
    
    setGamesList(res.data.games);
  });

  const prevPage = () => {
    if (pageIndex > 0) {
      setPageIndex(prev => prev -= 1);
    }
  }

  const nextPage = () => {
    if (pageIndex < totalPages) {
      setPageIndex(prev => prev += 1);
    }
  }

  const getWinnedSum = (user: any) => {
    let sum = 0.0;
    user.winnedGames.forEach((game: any) => {
      sum += ((game.sol_price! * 2) / 100) * 94.9;
    });
    return sum;
  }

  useEffect(() => {
    fetchGames();
  }, [pageIndex, searchText, searchType]);

  useEffect(() => {
    fetchStats();
    fetchGames();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="page-header">
            RumbleBattle <small>Управление</small>
          </h1>
        </div>

        <div className="col-12 col-md-12">
          <div className="card mb-4 p-4">
            <h5 className="card__title">Статистика</h5>

            {stats != undefined ? <div className={styles.stats}>
              <div className={styles.stats_item}>
                <div className={styles.stats_item__title}>Всего боев</div>
                <div className={styles.stats_item__val}>{stats.allgames}</div>
              </div>

              <div className={styles.stats_item}>
                <div className={styles.stats_item__title}>Завершенные</div>
                <div className={styles.stats_item__val}>{stats.wingames}</div>
              </div>

              <div className={styles.stats_item}>
                <div className={styles.stats_item__title}>Отмененные</div>
                <div className={styles.stats_item__val}>{stats.declined}</div>
              </div>

              <div className={styles.stats_item + " " + styles.green}>
                <div className={styles.stats_item__title}>Весь трафик SOL</div>
                <div className={styles.stats_item__val}>{stats.allsum.toFixed(3)} <small>SOL</small></div>
              </div>

              <div className={styles.stats_item + " " + styles.green}>
                <div className={styles.stats_item__title}>Выплачено бойцам</div>
                <div className={styles.stats_item__val}>{stats.usersum.toFixed(3)} <small>SOL</small></div>
              </div>

              <div className={styles.stats_item + " " + styles.green}>
                <div className={styles.stats_item__title}>Заработано комиссии</div>
                <div className={styles.stats_item__val}>{stats.procsum.toFixed(3)} <small>SOL</small></div>
              </div>
            </div> : <></>}

            <h5 className="card__title mt-3">ТОП-3</h5>
            {top.length > 0 ? <div className={styles.toplist}>
              {top.slice(0, 3).map((user: any, i: number) => <div className={styles.toplist_item}>
                <div className={styles.toplist_item__place}>
                  <img src={"/assets/images/places/" + (i == 0 ? "first" : (i == 1 ? "second" : "third")) + ".png"} />
                </div>
                <div className={styles.toplist_item__avatar}>
                  <div style={{backgroundImage: "url(" + user.avatar + ")"}}></div>
                </div>
                <div className={styles.toplist_item__data}>
                  <div>{user.name}</div>
                  <div>{user.login.substring(0, 4)}....{user.login.substring(user.login.length - 4, user.login.length)}</div>
                </div>
                <div className={styles.toplist_item__wins}>
                  <div>{user.winnedGames.length} побед</div>
                  <div>+{getWinnedSum(user).toFixed(2)} <small>SOL</small></div>
                </div>
              </div>)}
            </div> : <></>}

          </div>
        </div>

        <div className="col-12 col-md-12">
          <div className="card mb-4 p-4">
            <h5 className="card__title">Список боев</h5>

            <div className="input-group mb-4">
              <div className="flex-fill position-relative">
                <div className="input-group">
                  <DebounceInput
                    className="form-control ps-35px"
                    minLength={2}
                    type="text"
                    placeholder="Поиск по кошельку, имени, телеграму..."
                    debounceTimeout={800}
                    onChange={event => setSearchText(event.target.value)}
                    />
                  <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0">
                    <i className="fa fa-search opacity-5"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.fight_tabs}>
              <div className={styles.fight_tabs__item + (searchType == "" ? " " + styles.active : "")} onClick={() => setSearchType("")}>Все</div>
              <div className={styles.fight_tabs__item + (searchType == "ended" ? " " + styles.active : "")} onClick={() => setSearchType("ended")}>Завершенные</div>
              <div className={styles.fight_tabs__item + (searchType == "declined" ? " " + styles.active : "")} onClick={() => setSearchType("declined")}>Отклоненные</div>
              <div className={styles.fight_tabs__item + (searchType == "fight" ? " " + styles.active : "")} onClick={() => setSearchType("fight")}>В бою</div>
              <div className={styles.fight_tabs__item + (searchType == "waiting" ? " " + styles.active : "")} onClick={() => setSearchType("waiting")}>Ожидание</div>
            </div>

            <XTable
              isLoading={isGamesLoading}
              searchVisible={false}
              searchAction={() => {}}
              ths={gamesThs} 
              fields={gamesTable} />

            <div className="d-md-flex align-items-center">
              <div className="me-md-auto text-md-left text-center mb-2 mb-md-0" style={{color: "#fff"}}>
                Показано с 1 по {gamesList.length} из {totalGames} записей
              </div>
              <ul className="pagination mb-0 justify-content-center">
                <li className="page-item"><a className="page-link" onClick={() => prevPage()}><i className="fas fa-chevron-left"></i></a></li>
                <li className="page-item active"><a className="page-link" href="#">{pageIndex + 1} страница из {totalPages + 1}</a></li>
                <li className="page-item"><a className="page-link" onClick={() => nextPage()}><i className="fas fa-chevron-right"></i></a></li>
              </ul>
            </div>

          </div>
        </div>

      </div>
    </>
  )
}

export default observer(RumbleBattle)