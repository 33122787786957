import React from "react";
import SignIn from "../../pages/auth/SignIn";
import Drops from "../../pages/main/components/Drops";
import Mint from "../../pages/main/components/Mint";
import Payments from "../../pages/main/components/Payments";
import Portfolio from "../../pages/main/components/Portfolio";
import Raffles from "../../pages/main/components/Raffles";
import RumbleBattle from "../../pages/main/components/RumbleBattle";
import TelegramBot from "../../pages/main/components/TelegramBot";
import Tourneys from "../../pages/main/components/Tourneys";
import Users from "../../pages/main/components/Users";
import Votings from "../../pages/main/components/Votings";
import WhitePaper from "../../pages/main/components/WhitePaper";
import Home from "../../pages/main/Home";

export interface IRoute {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

export enum RouteNames {
  AUTH = '/signin',
  HOME = '/',
  PORTFOLIO = '/portfolio',
  VOTINGS = '/votings',
  RAFFLES = '/raffles',
  MINT = '/mint',
  USERS = '/users',
  PAYMENTS = '/payments',
  DROPS = '/drops',
  TOURNEYS = '/tourneys',
  BOT = '/bot',
  WHITEPAPER = '/whitepaper',
  RUMBLEBATTLE = '/rumblebattle'
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.AUTH, exact: true, component: <SignIn /> },
  { path: RouteNames.HOME, exact: true, component: <Portfolio /> },
  { path: RouteNames.PORTFOLIO, exact: true, component: <Portfolio /> },
  { path: RouteNames.VOTINGS, exact: true, component: <Votings /> },
  { path: RouteNames.RAFFLES, exact: true, component: <Raffles /> },
  { path: RouteNames.MINT, exact: true, component: <Mint /> },
  { path: RouteNames.USERS, exact: true, component: <Users /> },
  { path: RouteNames.PAYMENTS, exact: true, component: <Payments /> },
  { path: RouteNames.TOURNEYS, exact: true, component: <Tourneys /> },
  { path: RouteNames.DROPS, exact: true, component: <Drops /> },
  { path: RouteNames.WHITEPAPER, exact: true, component: <WhitePaper /> },
  { path: RouteNames.BOT, exact: true, component: <TelegramBot /> },
  { path: RouteNames.RUMBLEBATTLE, exact: true, component: <RumbleBattle /> }
]

export const privateRoutes: IRoute[ ] = [

]