import React, { FC, ReactElement } from "react";

import styles from "../styles/Modal.module.scss";

interface ModalProps {
  visible: boolean
  title: string
  content: ReactElement | string
  footer?: ReactElement | string
  maxWidth?: string
  onClose: () => void
}

const Modal: FC<ModalProps> = ({visible, title, content, footer, maxWidth = "550px", onClose}) => {

  const onKeydown = ({ key }: KeyboardEvent) => {
    switch (key) {
      case 'Escape':
        onClose();
        break;
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', onKeydown)
    return () => document.removeEventListener('keydown', onKeydown)
  })

  if (!visible) return null
  
  return (
    <div className={styles.modal}>
      <div className={styles.modal_dialog} style={{maxWidth: maxWidth}} onClick={e => e.stopPropagation()}>
        <div className={styles.modal_header}>
          <h3 className={styles.modal_header__title}
            style={{
              textAlign: parseInt(maxWidth) > 420 ? "left" : "left",
              paddingLeft: parseInt(maxWidth) > 420 ? "0px" : "0px"
            }}>{title}</h3>
          <span className={styles.modal_header__close} onClick={onClose}>
            <i className="far fa-times"></i>
          </span>
        </div>
        <div className={styles.modal_body}
          style={{
            padding: parseInt(maxWidth) > 420 ? "0px 0px" : "0px"
          }}>
          <div className={styles.modal_body__content}>{content}</div>
        </div>
        {footer && <div className={styles.modal_footer}>{footer}</div>}
      </div>
    </div>
  )
}

export default Modal