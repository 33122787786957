export enum ControllerName {
  NEWS = "/news",
  VIDEOS = "/video",
  USERS = "/users",
  GAMES = "/games",
  CORE = "/core",
  MBC = "/mbc",
  CHATS = "/chats",
  VOTINGS = "/votings",
  RAFFLES = "/raffles",
  DROPS = "/drops"
}

export enum AccountPageIndex {
  SUBSCRIBES = "subscribes",
  INFO = "info",
  VIEWS = "views",
  SEE_LATER = "see_later",
  SETTINGS = "settings"
}

export enum SettingsSubPage {
  MAIN = "main",
  REGIONS = "regions",
  BANNER = "banner",
  CHAT = "chat",
  LIVE = "live",
  ROLES = "roles"
}

export interface SearchUsersProps {
  page: number;
  limit: number;
  search: string;
  type: string;
}
