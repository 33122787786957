import { UploadProps } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { observer } from "mobx-react-lite";
import { FC, useContext, useState } from "react";
import { toast } from "react-toastify";
import { Context } from "../../..";
import { API_URL } from "../../../core/defaults";
import useWindowDimensions from "../../../core/hooks/useWindowDimensions";

const WhitePaper: FC = () => {

  const { store } = useContext(Context);

  const {width, height} = useWindowDimensions();

  const [selectedWPLang, setSelectedWPLang] = useState("RU");
  const [actualRUWP, setActualRUWP] = useState("https://top-racing.tv/whitepaper.pdf");
  const [actualENWP, setActualENWP] = useState("https://top-racing.tv/whitepaper_en.pdf");

  const props: UploadProps = {
    name: 'whitepaper', multiple: false, maxCount: 1, showUploadList: true,
    headers: {"Authorization": "Bearer " + store.user.token},
    action: `${API_URL}/mbc/whitepaper/upload/RU`,
    onChange: async (info) => {
      const { status } = info.file;
      if (status === 'done') {
        toast("WhitePaper успешно загружен!", {type: 'success'});
        console.log(`${info.file.name} file uploaded successfully.`);
      } else {
        console.log(status);
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    onDrop(e) {console.log('Dropped files', e.dataTransfer.files);},
  };

  const props2: UploadProps = {
    name: 'whitepaper', multiple: false, maxCount: 1, showUploadList: true,
    headers: {"Authorization": "Bearer " + store.user.token},
    action: `${API_URL}/mbc/whitepaper/upload/EN`,
    onChange: async (info) => {
      const { status } = info.file;
      if (status === 'done') {
        toast("WhitePaper успешно загружен!", {type: 'success'});
        console.log(`${info.file.name} file uploaded successfully.`);
      } else {
        console.log(status);
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    onDrop(e) {console.log('Dropped files', e.dataTransfer.files);},
  };
  
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="page-header">
            WhitePaper <small>Загрузить</small>
          </h1>

          <div className="row">
            <div className="col-12 col-md-6">
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <i className="fas fa-image-polaroid"></i>
                </p>
                <p className="ant-upload-text">Whitepaper RU</p>
                <p className="ant-upload-hint">
                  нажмите или перенесите PDF в эту область
                </p>
              </Dragger>
            </div>
            <div className="col-12 col-md-6" style={{marginTop: width <= 1000 ? "100px" : "0px"}}>
              <Dragger {...props2}>
                <p className="ant-upload-drag-icon">
                  <i className="fas fa-image-polaroid"></i>
                </p>
                <p className="ant-upload-text">Whitepaper EN</p>
                <p className="ant-upload-hint">
                  нажмите или перенесите PDF в эту область
                </p>
              </Dragger>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(WhitePaper)