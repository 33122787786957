import axios from "axios";
import { Id } from "react-toastify";
import { API_URL } from "../defaults";
import $api from "../http";
import { BuyTicketsDto } from "../models/dto/buy-tickets.dto";
import { CreateRaffleDto } from "../models/dto/create-raffle.dto";
import { Region } from "../models/Region";
import { ControllerName, SearchUsersProps } from "../types";

export class ApiService {

  constructor() {}

  static users = {
    signIn: async(dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `authorize`), dto);
    },
    getUsers: async (params: any) => {
      const data = await $api.get(this.buildUrl(ControllerName.USERS, `list${this.buildParams(params)}`));
      return data;
    },
    getPayments: async (params: any) => {
      const data = await $api.get(this.buildUrl(ControllerName.USERS, `payments/list${this.buildParams(params)}`));
      return data;
    },
    deleteUser: async (id: number) => {
      const data = await $api.delete(this.buildUrl(ControllerName.USERS, `account/${id}`));
      return data;
    }
  }

  static games = {
    getGamesStats: async () => {
      return await $api.get(this.buildUrl(ControllerName.GAMES, `list/stats`));
    },
    getGames: async (query: {
      userId: number;
      status: number;
      limit: number;
      filter: string;
      search: string;
      page: number;
    }) => {
      return await $api.get(this.buildUrl(ControllerName.GAMES, `list${this.buildParams(query)}`));
    },
    getTopWinnersGenerate: async () => {
      return await $api.get(this.buildUrl(ControllerName.GAMES, `top/generate`));
    },
    getTopWinners: async () => {
      return await $api.get(this.buildUrl(ControllerName.GAMES, `top`));
    },
    deleteGame: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.GAMES, `list/${id}`));
    },
    resetHeartsToAll: async () => {
      return await $api.post(this.buildUrl(ControllerName.GAMES, `hearts/reset`));
    },
    resetHeartsByUser: async (uid: number) => {
      return await $api.post(this.buildUrl(ControllerName.GAMES, `hearts/reset/${uid}`));
    }
  }

  static mbc = {
    getNfts: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.MBC, `nft/get`));
      return data;
    },
    addNft: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.MBC, `nft/add`), dto);
      return data;
    },
    updateNft: async (id: number, dto: any) => {
      const data = await $api.put(this.buildUrl(ControllerName.MBC, `nft/update/${id}`), dto);
      return data;
    },
    deleteNft: async (id: number) => {
      const data = await $api.delete(this.buildUrl(ControllerName.MBC, `nft/delete/${id}`));
      return data;
    },
    setPoolSize: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.MBC, `poolsize/set`), dto);
      return data;
    },
    getOffProjs: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.MBC, `offline/get`));
      return data;
    },
    addOffProj: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.MBC, `offline/add`), dto);
      return data;
    },
    updateOffProj: async (id: number, dto: any) => {
      const data = await $api.put(this.buildUrl(ControllerName.MBC, `offline/update/${id}`), dto);
      return data;
    },
    deleteOffProj: async (id: number) => {
      const data = await $api.delete(this.buildUrl(ControllerName.MBC, `offline/delete/${id}`));
      return data;
    },
    getMintInfo: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.MBC, `mint/info`));
      return data;
    },
    updateMintInfo: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.MBC, `mint/info`), dto);
      return data;
    },
    clearCache: async () => {
      const data = await $api.delete(this.buildUrl(ControllerName.MBC, `nft/cache`));
      return data;
    },
    botSendToAll: async (message: string) => {
      const data = await $api.post(this.buildUrl(ControllerName.MBC, `tgbot/send/toall/`), {message});
      return data;
    },
    botSendToSpecify: async (ids: string, message: string) => {
      const data = await $api.post(this.buildUrl(ControllerName.MBC, `tgbot/send/specify/`), {ids, message});
      return data;
    }
  };

  static mints = {
    getMints: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.MBC, `mints/list`));
      return data;
    },
    addMint: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.MBC, `mints/list`), dto);
      return data;
    },
    updateMint: async (id: number, dto: any) => {
      const data = await $api.put(this.buildUrl(ControllerName.MBC, `mints/list/${id}`), dto);
      return data;
    },
    deleteMint: async (id: number) => {
      const data = await $api.delete(this.buildUrl(ControllerName.MBC, `mints/list/${id}`));
      return data;
    }
  };

  static soldouts = {
    getSoldouts: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.MBC, `soldouts/list`));
      return data;
    },
    createSoldout: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.MBC, `soldouts/list`), dto);
      return data;
    },
    updateSoldout: async (id: number, dto: any) => {
      const data = await $api.put(this.buildUrl(ControllerName.MBC, `soldouts/list/${id}`), dto);
      return data;
    },
    deleteSoldout: async (id: number) => {
      const data = await $api.delete(this.buildUrl(ControllerName.MBC, `soldouts/list/${id}`));
      return data;
    }
  };

  static votings = {
    getVotings: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.VOTINGS, `list`));
      return data;
    },
    createVoting: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.VOTINGS, `list`), dto);
      return data;
    },
    updateVoting: async (id: number, dto: any) => {
      const data = await $api.put(this.buildUrl(ControllerName.VOTINGS, `list/${id}`), dto);
      return data;
    },
    deleteVoting: async (id: number) => {
      const data = await $api.delete(this.buildUrl(ControllerName.VOTINGS, `list/${id}`));
      return data;
    },
    voteVoting: async (id: number, dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.VOTINGS, `list/${id}`), dto);
      return data;
    }
  };

  static tourneys = {
    getOffTourneys: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.MBC, `tourney/get`));
      return data;
    },
    addOffTourney: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.MBC, `tourney/add`), dto);
      return data;
    },
    updateOffTourney: async (id: number, dto: any) => {
      const data = await $api.put(this.buildUrl(ControllerName.MBC, `tourney/update/${id}`), dto);
      return data;
    },
    deleteOffTourney: async (id: number) => {
      const data = await $api.delete(this.buildUrl(ControllerName.MBC, `tourney/delete/${id}`));
      return data;
    },
    regToOffTourney: async (id: number, dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.MBC, `tourney/reg`), dto);
      return data;
    }
  };

  static raffles = {
    getRaffles: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.RAFFLES, `list`));
      return data;
    },
    createRaffle: async (dto: CreateRaffleDto) => {
      const data = await $api.post(this.buildUrl(ControllerName.RAFFLES, `list`), dto);
      return data;
    },
    updateRaffle: async (id: number, dto: CreateRaffleDto) => {
      const data = await $api.put(this.buildUrl(ControllerName.RAFFLES, `list/${id}`), dto);
      return data;
    },
    deleteRaffle: async (id: number) => {
      const data = await $api.delete(this.buildUrl(ControllerName.RAFFLES, `list/${id}`));
      return data;
    },
    buyRaffleTickets: async (id: number, dto: BuyTicketsDto) => {
      const data = await $api.post(this.buildUrl(ControllerName.RAFFLES, `list/${id}`), dto);
      return data;
    },
    finishRaffle: async (id: number) => {
      const data = await $api.post(this.buildUrl(ControllerName.RAFFLES, `finish/${id}`), {});
      return data;
    }
  };

  static drops = {
    getUniverses: async () => {
      return await $api.get(this.buildUrl(ControllerName.DROPS, `universes`));
    },
    getAllDrops: async () => {
      return await $api.get(this.buildUrl(ControllerName.DROPS, `all`));
    },
    refreshUniverses: async () => {
      return await $api.post(this.buildUrl(ControllerName.DROPS, `universes`));
    },
    rechargeHolders: async () => {
      return await $api.get(this.buildUrl(ControllerName.DROPS, `holders/recharge`));
    },
    revealDrop: async (drop: number) => {
      return await $api.get(this.buildUrl(ControllerName.DROPS, `reveal/${drop}`));
    },
    handleUsersNfts: async () => {
      return await $api.get(this.buildUrl(ControllerName.DROPS, `handle`));
    },
    getParsedNfts: async () => {
      return await $api.get(this.buildUrl(ControllerName.DROPS, `nfts/parse`));
    },
    addHashes: async (dto: {
      drop: number,
      hashes: string[]
    }) => {
      return await $api.post(this.buildUrl(ControllerName.DROPS, `hashes/add`), dto);
    }
  }

  static buildUrl(
    controller: ControllerName,
    method: string
  ) {
    return `${API_URL}${controller}/${method}`
  }

  static buildUrl2(
    controller: ControllerName,
    method: string
  ) {
    return `${controller}/${method}`
  }

  static buildParams(map: any) {
    let query = "";
    for (let key in map) {
      if (query == "") {
        query = `?${key}=${map[key]}`;
      } else {
        query += `&${key}=${map[key]}`;
      }
    }
    return query;
  }

}