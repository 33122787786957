import { Badge, Layout, Row } from "antd";
import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import Avatar from "react-avatar";
import { useLocation, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { Context } from "..";
import useWindowDimensions from "../core/hooks/useWindowDimensions";

import styles from '../styles/Navbar.module.scss';
import MenuLink from "./MenuLink";

const Sidebar: FC = () => {

  let resolved = useResolvedPath("/");
  let match = useMatch({ path: resolved.pathname, end: true });

  let { width, height } = useWindowDimensions();

  let { store } = useContext(Context);
  let navigate = useNavigate();

  return (
    <div id="sidebar" className="app-sidebar">
      <div className="app-sidebar-content" data-scrollbar="true" data-height="100%">
        <div className="menu">

          <div className="menu-header">Мероприятия</div>
          <MenuLink to="/votings" icon="fa fa-check-to-slot">Голосования</MenuLink>
          <MenuLink to="/raffles" icon="fa fa-trophy-star">Розыгрыши</MenuLink>
          <MenuLink to="/tourneys" icon="fa fa-bolt">Турниры</MenuLink>

          <div className="menu-header">Управление</div>
          <MenuLink to="/" icon="fa fa-chart-pie">Портфель</MenuLink>
          <MenuLink to="/mint" icon="fa fa-gears">Минт-Формы</MenuLink>
          <MenuLink to="/drops" icon="fa fa-rectangle-vertical-history">Дропы</MenuLink>
          <MenuLink to="/whitepaper" icon="fa fa-toilet-paper">WhitePaper</MenuLink>
          <MenuLink to="/users" icon="fa fa-users">Пользователи</MenuLink>
          <MenuLink to="/payments" icon="fa fa-cart-shopping">Оплаты</MenuLink>
          <MenuLink to="/bot" icon="fa fa-message-bot">Telegram Бот</MenuLink>
          <MenuLink to="/rumblebattle" icon="fa fa-swords">RumbleBattle</MenuLink>

        </div>
      </div>
      <button className="app-sidebar-mobile-backdrop" data-dismiss="sidebar-mobile"></button>
    </div>
  )
}

export default observer(Sidebar)