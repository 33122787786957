import { IUser } from "../models/User";
import { makeAutoObservable } from "mobx";
import AuthService from "../services/AuthService";
import { toast } from "react-toastify";
import { ApiService } from "../api";
import { IChatMessage } from "../models/ChatMessage";
import { Socket } from "socket.io-client";
import { IChatRoom } from "../models/ChatRoom";
import { IPayment } from "../models/Payment";
import { XTableCol } from "../../components/XTable";

export default class Store {
  user = {} as IUser;
  isAuth = false;

  liveChatMessages = [] as IChatMessage[];
  socket = {} as WebSocket;
  socketData = {} as any;

  isSocketed = false;

  onlineCount = 0;

  payments = [] as IPayment[];
  paysTable = [] as XTableCol[][];

  constructor() {
    makeAutoObservable(this);
  }

  setPayments(tbl: IPayment[]) {
    this.payments = tbl;
  }

  setPaysTable(tbl: XTableCol[][]) {
    this.paysTable = tbl;
  }

  setIsSocketed(st: boolean) {
    this.isSocketed = st;
  }

  setSocketData(data: any) {
    this.socketData = data;
  }

  setOnlineCount(count: number) {
    this.onlineCount = count;
  }

  setSocket(sock: WebSocket) {
    this.socket = sock;
  }

  setAuth(bool: boolean) {
    this.isAuth = bool;
  }

  setUser(user: IUser) {
    this.user = user;
  }

  setMessages(list: IChatMessage[]) {
    this.liveChatMessages = list;
  }

  async getAccount() {
    return true;
  }

  async signIn(login: string, password: string) {
    try {
      if (login.toLowerCase() != "iloveholders" || password != "we4242mbcrm") {
        toast("Неверные логин и/или пароль!", {type: 'error'});
        return false;
      } else {
        localStorage.setItem("user", JSON.stringify({
          login: "Admin",
          email: "admin@metabuildersclub.io",
          id: 1,
          role: "ADMIN"
        }));
        localStorage.setItem("token", "super_token");
        localStorage.setItem("authed", "1");
        this.setAuth(true);
        this.setUser({
          login: "Admin",
          email: "admin@metabuildersclub.io",
          id: 1,
          role: "ADMIN"
        });
        return true;
      }
    } catch (e: any) {
      console.log(e);
      return false;
    }
  }

  async signUp(name: string, email: string, password: string) {
    try {
      const response = await AuthService.signUp(name, email, password);
      if (response.data.status && (response.data.status == 202 || response.data.status == 400)) {
        toast(response.data.message, {type: 'error'});
        return false;
      } else {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("authed", "1");
        this.setAuth(true);
        this.setUser(response.data.user);
        return true;
      }
    } catch (e: any) {
      console.log(e);
      return false;
    }
  }

  async applyAuth() {
    const user = JSON.parse(localStorage.getItem("user")!);
    this.setAuth(true);
    this.setUser(user as IUser);
  }

  async logout() {
    localStorage.clear();
    this.setAuth(false);
    this.setUser({} as IUser);
    window.location.reload();
  }

}