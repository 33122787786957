import { PlusOutlined } from "@ant-design/icons";
import { Switch, UploadProps } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Context } from "../../..";
import Modal from "../../../components/Modal";
import { ApiService } from "../../../core/api";
import { API_URL } from "../../../core/defaults";
import { useFetching } from "../../../core/hooks/useFetching";
import { IVoting, Voting } from "../../../core/models/Voting";

import styles from "../../../styles/Votings.module.scss";

const Votings: FC = () => {

  const { store } = useContext(Context);

  const [votingsList, setVotingsList] = useState([] as IVoting[]);

  const [editedVoting, setEditedVoting] = useState(undefined as IVoting | undefined);
  const [editedVotingVars, setEditedVotingVars] = useState(["Вариант 1", ""] as string[]);
  const [editOrAdd, setEditOrAdd] = useState(0);

  const [isAddVotingVisible, setIsAddVotingVisible] = useState(false);
  const [isEditVotingVisible, setIsEditVotingVisible] = useState(false);
  const [isDeleteVotingVisible, setIsDeleteVotingVisible] = useState(false);

  const props: UploadProps = {
    name: 'image', multiple: false, maxCount: 1, showUploadList: true,
    headers: {"Authorization": "Bearer " + store.user.token},
    action: `${API_URL}/mbc/customize/upload/`,
    onChange: async (info) => {
      const { status } = info.file;
      if (status === 'done') {
        toast("Изображение успешно загружено!", {type: 'success'});
        let path = "https://" + info.file.response.save.split("www-root/data/www/")[1];
        console.log(path);
        setEditedVoting({
          ...editedVoting, banner: path
        });
        console.log(`${info.file.name} file uploaded successfully.`);
      } else {
        console.log(status);
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    onDrop(e) {console.log('Dropped files', e.dataTransfer.files);},
  };

  const addVVar = (value: string) => {
    setEditedVotingVars([...editedVotingVars, value]);
  }

  const updateVVar = (index: number, value: string) => {
    let arr = [...editedVotingVars];
    arr[index] = value;
    setEditedVotingVars([...arr]);
  }

  const deleteVVar = (index: number) => {
    setEditedVotingVars([...editedVotingVars.filter((v, i, a) => i != index)]);
  }

  const [fetchVotings, isVotingsLoading, errorVotings] = useFetching(async () => {
    const res = await ApiService.votings.getVotings();
    console.log(res.data);

    res.data.votings.sort((a: any, b: any) => {
      return a.id > b.id ? -1 : (a.id < b.id ? 1 : 0);
    });

    setVotingsList(res.data.votings);
  });

  const addNewVoting = () => {
    setEditedVoting({
      banner: "",
      title: "",
      description: "",
      variables: "",
      urls: "",
      status: 1,
      statushh: 0,
      hhDate: 0
    });
    setEditOrAdd(1);
  }
  const addNewVotingAction = async () => {
    let variables = editedVotingVars.join(";");

    if (editedVoting?.statushh == 1) {
      editedVoting.hhDate = moment().unix();
    }

    const res = await ApiService.votings.createVoting({...editedVoting, variables});
    console.log(res.data);
    setEditedVoting(undefined);

    toast("Голосование успешно добавлено!", {type: "success"});

    fetchVotings();
  }

  const editVoting = (voting: IVoting) => {
    setEditOrAdd(0);
    setEditedVoting(voting);
    setEditedVotingVars(voting.variables!.split(";"));
  }

  const saveVoting = () => {
    setIsEditVotingVisible(true);
  }

  const saveVotingAction = async () => {
    setIsEditVotingVisible(false);

    const res = await ApiService.votings.updateVoting(editedVoting?.id!, {
      banner: editedVoting?.banner,
      title: editedVoting?.title,
      description: editedVoting?.description,
      variables: editedVotingVars.join(";"),
      status: editedVoting?.status,
      statushh: editedVoting?.statushh,
      hhDate: editedVoting?.statushh == 1 ? moment().unix() : 0
    });

    console.log(res.data);

    fetchVotings();
    
    toast("Голосование успешно обновлено!", {type: "success"});
  }

  const deleteVoting = (voting: IVoting) => {
    setEditedVoting(voting);
    setIsDeleteVotingVisible(true);
  }

  const deleteVotingAction = async () => {
    setIsDeleteVotingVisible(false);
    setEditedVoting(undefined);
    const res = await ApiService.votings.deleteVoting(editedVoting?.id!);
    fetchVotings();
    toast("Голосование успешно удалено!", {type: "success"});
  }

  const closeForm = () => {
    setEditedVoting(undefined);
  }

  useEffect(() => {
    fetchVotings();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="page-header">
            Голосования <small>Управление</small>
          </h1>

          <div className="card mb-4">
            <div className="p-4">
              <div className="row">
                <div className="col-12 col-md-5">
                  
                  <h5 className="card__title">Список голосований 
                    <span className="ct_action" style={{right: "16px"}} onClick={() => addNewVoting()}><PlusOutlined /> <b style={{marginLeft: "6px", fontWeight: "500"}}>Создать</b></span>
                  </h5>
                  
                  <div className={styles.votings_list}>
                    {votingsList.length == 0 ? <div className={styles.votings_list__empty}>
                      Голосования отсутствуют! Создайте первое при помощи кнопки "<i className="far fa-plus"></i> Создать".
                    </div> : <></>}

                    {votingsList.map(item => <div className={styles.votings_list__item} onClick={() => editVoting(item)}>
                      <div className={styles.votings_list__item_status}><span className={item.status == 0 ? "" : styles.votings_list__item_status_active}></span></div>
                      <div className={styles.votings_list__item_go}>
                        <i className="far fa-arrow-right"></i>
                      </div>
                      <div className={styles.votings_list__item_data}>
                        <div className={styles.votings_list__item_title}>{item.title}</div>
                        <div className={styles.votings_list__item_desc}>{item.description}</div>
                      </div>
                    </div>)}
                  </div>

                </div>
                
                {editedVoting != undefined ? <div className="col-12 col-md-7">
                  <div className={styles.form_fields}>
                    <div className={styles.form_title}>{editOrAdd == 0 ? "Редактировать голосование" : "Создание нового голосования"}</div>
                    <div className={styles.form_fields_item}>
                      <div className={styles.form_fields_item__label}>Заголовок</div>
                      <input type="text" placeholder="Заголовок голосования" name="title" value={editedVoting.title} onChange={(event) => setEditedVoting({...editedVoting, title: event.target.value})} />
                    </div>
                    <div className={styles.form_fields_item}>
                      <div className={styles.form_fields_item__label}>Описание</div>
                      <textarea rows={5} placeholder="Описание голосования" name="description" value={editedVoting.description} onChange={(event) => setEditedVoting({...editedVoting, description: event.target.value})}></textarea>
                    </div>

                    <div className={styles.form_fields_item}>
                      <div className={styles.form_fields_item__label}>Изображение</div>
                      <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                          <i className="fas fa-image-polaroid"></i>
                        </p>
                        {editedVoting.banner != "" && editedVoting.banner != "https://core.mbc.family/uploads/avatars/default_avatar.png" ? <img src={editedVoting.banner} id="banner_fill" /> : <></>}
                        <p className="ant-upload-text">Картинка</p>
                        <p className="ant-upload-hint">
                          нажмите или перенесите изображение в эту область
                        </p>
                      </Dragger>
                    </div>

                    <div className={styles.form_fields_item}>
                      <div className={styles.form_fields_item__label}>Варианты ответов</div>
                      
                      <div className={styles.form_fields_item__list}>
                        {editedVotingVars.map((item, index, arr) => <div className={styles.form_fields_item__list_item}>
                          <div className={styles.form_fields_item__list_item__input}>
                            <input type="text" placeholder="Вариант ответа 1..." value={item} onChange={(event) => updateVVar(index, event.target.value)} />
                          </div>
                          <div className={styles.form_fields_item__list_item__actions}>
                            <div onClick={() => addVVar("")}><i className="far fa-plus"></i></div>
                            <div onClick={() => deleteVVar(index)}><i className="far fa-times"></i></div>
                          </div>
                        </div>)}
                      </div>
                    </div>

                    <div className={styles.form_fields_item}>
                      <div className={styles.form_fields_item__label}>Статус голосования</div>
                      <Switch defaultChecked={editedVoting.status == 1} checked={editedVoting.status == 1} onChange={(event) => setEditedVoting({...editedVoting, status: event ? 1 : 0})} title="Статус голосования" /> <span style={{marginLeft: "6px"}}>Статус <b>({editedVoting.status == 1 ? "вкл" : "выкл"})</b></span>
                    </div>

                    <div className={styles.form_fields_item}>
                      <div className={styles.form_fields_item__label}>Ограничение 24 часа</div>
                      <Switch defaultChecked={editedVoting.statushh == 1} checked={editedVoting.statushh == 1} onChange={(event) => setEditedVoting({...editedVoting, statushh: event ? 1 : 0})} title="Статус голосования" /> <span style={{marginLeft: "6px"}}>Статус <b>({editedVoting.status == 1 ? "вкл" : "выкл"})</b></span>
                    </div>

                    <div className={styles.action}>
                      {editOrAdd == 0 ? <button className="topracing-btn topracing-btn-red" onClick={() => saveVoting()}>Сохранить</button> : <></>}
                      {editOrAdd == 0 ? <button className="topracing-btn topracing-btn-redd" style={{marginLeft: "16px"}} onClick={() => deleteVoting(editedVoting)}>Удалить</button> : <></>}
                      {editOrAdd == 1 ? <button className="topracing-btn topracing-btn-red" onClick={() => addNewVotingAction()}>Создать</button> : <></>}
                    </div>
                  </div>
                </div> : <></>}

              </div>
            </div>
          </div>
        </div>
      </div>


      <Modal
        visible={isAddVotingVisible}
        title="Добавить голосование"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите добавить Voting{editedVoting != undefined ? ` "${editedVoting!.title}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => saveVotingAction()}>Добавить</button>
          </div>
        </>
        }
        onClose={() => {setIsAddVotingVisible(false)}}/>

      <Modal
        visible={isEditVotingVisible}
        title="Обновить голосование"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите обновить Voting{editedVoting != undefined ? ` "${editedVoting!.title}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => saveVotingAction()}>Обновить</button>
          </div>
        </>
        }
        onClose={() => {setIsEditVotingVisible(false)}}/>

      <Modal
        visible={isDeleteVotingVisible}
        title="Удалить голосование"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите удалить Voting{editedVoting != undefined ? ` "${editedVoting!.title}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => deleteVotingAction()}>Удалить</button>
          </div>
        </>
        }
        onClose={() => {setIsDeleteVotingVisible(false)}}/>
    </>
  )
}

export default observer(Votings)