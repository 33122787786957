import { PlusOutlined } from "@ant-design/icons";
import { Switch, UploadProps } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Context } from "../../..";
import Modal from "../../../components/Modal";
import XTable, { XTableCol } from "../../../components/XTable";
import { ApiService } from "../../../core/api";
import { API_URL } from "../../../core/defaults";
import { useFetching } from "../../../core/hooks/useFetching";
import { IRaffle } from "../../../core/models/Raffle";
import { isNullOrUndefined } from "../../../core/utils";

import styles from "../../../styles/Raffles.module.scss";

const Tourneys: FC = () => {
  
  const { store } = useContext(Context);

  const [tourneysList, setTourneysList] = useState([] as any[]);

  const [editedTourney, setEditedTourney] = useState(undefined as any | undefined);
  const [editOrAdd, setEditOrAdd] = useState(0);

  const [isAddTourneyVisible, setIsAddTourneyVisible] = useState(false);
  const [isEditTourneyVisible, setIsEditTourneyVisible] = useState(false);
  const [isDeleteTourneyVisible, setIsDeleteTourneyVisible] = useState(false);
  const [isPauseTourneyVisible, setIsPauseTourneyVisible] = useState(false);
  const [isFinishTourneyVisible, setIsFinishTourneyVisible] = useState(false);

  const [ticketsOwnersTable, setTicketsOwnersTable] = useState([] as XTableCol[][]); // MAIN / 
  const ticketsOwnersThs: string[] = ["Логин", "Адрес", "Кол-во", "Сумма", "Бонусные", "Всего", "Кол-во победных"];  

  const props: UploadProps = {
    name: 'image', multiple: false, maxCount: 1, showUploadList: true,
    headers: {"Authorization": "Bearer " + store.user.token},
    action: `${API_URL}/mbc/customize/upload/`,
    onChange: async (info) => {
      const { status } = info.file;
      if (status === 'done') {
        toast("Изображение успешно загружено!", {type: 'success'});
        let path = "https://" + info.file.response.save.split("www-root/data/www/")[1];
        console.log(path);
        setEditedTourney({
          ...editedTourney, banner: path
        });
        console.log(`${info.file.name} file uploaded successfully.`);
      } else {
        console.log(status);
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    onDrop(e) {console.log('Dropped files', e.dataTransfer.files);},
  };

  const [fetchTourneys, isTourneysLoading, errorRaffles] = useFetching(async () => {
    const res = await ApiService.tourneys.getOffTourneys();
    console.log(res.data);

    res.data.list.sort((a: any, b: any) => {
      return a.id > b.id ? -1 : (a.id < b.id ? 1 : 0);
    });

    setTourneysList(res.data.list);

    if (editedTourney !== undefined) {
      setEditedTourney(res.data.list.find((v: any) => v.id == editedTourney.id));
    }
  });

  const addNewTourney = () => {
    setEditedTourney({
      name: "",
      description: "",
      date: "",
      results: "",
      banner: "",
      wlonly: false,
      status: 0
    });
    setEditOrAdd(1);
  }

  const addNewTourneyAction = async () => {
    const res = await ApiService.tourneys.addOffTourney({...editedTourney});
    console.log(res.data);

    setEditedTourney(undefined);

    toast("Турнир успешно добавлен!", {type: "success"});

    fetchTourneys();
  }

  const saveTourney = () => {
    setIsEditTourneyVisible(true);
  }

  const finishTourney = async () => {
    setIsFinishTourneyVisible(true);
  }

  const finishTourneyAction = async () => {
    setIsFinishTourneyVisible(false);
    
    const res = await ApiService.tourneys.updateOffTourney(editedTourney?.id!, {
      status: 2
    });

    console.log(res.data);

    fetchTourneys();
    
    toast("Турнир успешно завершен!", {type: "success"});
  }

  const saveRaffleAction = async () => {
    setIsEditTourneyVisible(false);

    const res = await ApiService.tourneys.updateOffTourney(editedTourney?.id!, {
      banner: editedTourney?.banner,
      name: editedTourney?.name,
      description: editedTourney?.description,
      date: editedTourney?.date,
      results: editedTourney?.results,
      wlonly: editedTourney?.wlonly,
      status: editedTourney?.status
    });

    console.log(res.data);

    fetchTourneys();
    
    toast("Турнир успешно обновлен!", {type: "success"});
  }

  const editTourney = (item: any) => {
    setEditOrAdd(0);
    setEditedTourney(item);
  }

  const deleteTourney = (item: any) => {
    setEditedTourney(item);
    setIsDeleteTourneyVisible(true);
  }

  const deleteTourneyAction = async () => {
    setIsDeleteTourneyVisible(false);
    setEditedTourney(undefined);
    const res = await ApiService.tourneys.deleteOffTourney(editedTourney?.id!);
    fetchTourneys();
    toast("Турнир успешно удален!", {type: "success"});
  }

  const exportToXls = async () => {}

  useEffect(() => {
    fetchTourneys();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="page-header">
            Турниры <small>Управление</small>
          </h1>

          <div className="card mb-4">
            <div className="p-4">
              <div className="row">
                <div className="col-12 col-md-5">
                
                  <h5 className="card__title">Список турниров 
                    <span className="ct_action" style={{right: "16px"}} onClick={() => addNewTourney()}><PlusOutlined /> <b style={{marginLeft: "6px", fontWeight: "500"}}>Создать</b></span>
                  </h5>

                  <div className={styles.votings_list}>
                    {tourneysList.length == 0 ? <div className={styles.votings_list__empty}>
                      Розыгрыши отсутствуют! Создайте первое при помощи кнопки "<i className="far fa-plus"></i> Создать".
                    </div> : <></>}

                    {tourneysList.map(item => <div className={styles.votings_list__item} onClick={() => editTourney(item)}>
                      <div className={styles.votings_list__item_status}><span style={{backgroundColor: item.status == 0 ? "#ffba00" : (item.status == 1 ? "#09bc09" : "#1d84ff")}}></span></div>
                      <div className={styles.votings_list__item_go}>
                        <i className="far fa-arrow-right"></i>
                      </div>
                      <div className={styles.votings_list__item_data}>
                        <div className={styles.votings_list__item_title}>{item.name}</div>
                        <div className={styles.votings_list__item_desc}>{item.description}</div>
                        <div className={styles.votings_list__item_desc}>{item.date}</div>
                      </div>
                    </div>)}
                  </div>
                  </div>

                  {editedTourney != undefined ? <div className="col-12 col-md-7">
                  {(editedTourney.status == 1 || editedTourney.status == 2) && !isNullOrUndefined(editedTourney.tickets) ? <div className={styles.form_fields}>
                    <div className={styles.form_title}>Розыгрыш {editedTourney.status == 1 ? "запущен" : "завершен"}</div>

                    <div className={styles.stats_counts}>
                      <div className={styles.stats_counts__item}>
                        <div>Кол-во купленных билетов</div>
                        <div>0</div>
                      </div>
                      <div className={styles.stats_counts__item}>
                        <div>Сумма</div>
                        <div>0 SOL</div>
                      </div>
                    </div>
                    <div className={styles.stats_counts}>
                      <div className={styles.stats_counts__item}>
                        <div>Кол-во бонусных билетов</div>
                        <div>0</div>
                      </div>
                      {editedTourney.status == 1 ? <div className={styles.stats_counts__item}>
                        <div>Время до завершения</div>
                        <div>0</div>
                      </div> : <></>}
                    </div>

                    <div className={styles.scrollable}>
                      <a onClick={() => exportToXls()} className="btn btn-primary" style={{marginBottom: "16px"}}>Выгрузить в XLS</a>

                      <XTable
                        isLoading={false}
                        searchVisible={false}
                        searchAction={() => {}}
                        ths={ticketsOwnersThs} 
                        fields={ticketsOwnersTable} />
                    </div>

                    {editedTourney.status == 1 ? <div className={styles.action}>
                      <button className="topracing-btn topracing-btn-green" style={{marginRight: "12px"}} onClick={() => setIsFinishTourneyVisible(true)}>Завершить и получить результаты</button>
                      <button className="topracing-btn topracing-btn-redd" onClick={() => setIsPauseTourneyVisible(true)}>Пауза</button>
                    </div> : <></>}
                  </div> : <></>}

                  <div className={styles.form_fields}>
                    <div className={styles.form_title}>{editOrAdd == 0 ? "Редактировать розыгрыш" : "Создание нового розыгрыша"}</div>
                    <div className={styles.form_fields_item}>
                      <div className={styles.form_fields_item__label}>Наименование</div>
                      <input type="text" disabled={editedTourney.status == 2} placeholder="Наименование турнира" name="title" value={editedTourney.name} onChange={(event) => setEditedTourney({...editedTourney, name: event.target.value})} />
                    </div>
                    <div className={styles.form_fields_item}>
                      <div className={styles.form_fields_item__label}>Описание</div>
                      <textarea rows={5} disabled={editedTourney.status == 2} placeholder="Описание турнира" name="description" value={editedTourney.description} onChange={(event) => setEditedTourney({...editedTourney, description: event.target.value})}></textarea>
                    </div>

                    <div className={styles.form_fields_item}>
                      <div className={styles.form_fields_item__label}>Изображение</div>
                      <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                          <i className="fas fa-image-polaroid"></i>
                        </p>
                        {editedTourney.banner != "" ? <img src={editedTourney.banner} id="banner_fill" /> : <></>}
                        <p className="ant-upload-text">Картинка</p>
                        <p className="ant-upload-hint">
                          нажмите или перенесите изображение в эту область
                        </p>
                      </Dragger>
                    </div>
                    
                    <div className={styles.flexed_fields}>
                      <div className={styles.form_fields_item}>
                        <div className={styles.form_fields_item__label}>Дата и время проведения</div>
                        <input type="text" disabled={editedTourney.status == 2} placeholder="Дата и время проведения" name="date" value={editedTourney.date} onChange={(event) => setEditedTourney({...editedTourney, date: event.target.value})} />
                      </div>
                    </div>

                    <div className={styles.flexed_fields}>
                      <div className={styles.form_fields_item}>
                        <div className={styles.form_fields_item__label}>Статус розыгрыша</div>
                        <Switch disabled={editedTourney.status == 2} defaultChecked={editedTourney.status == 1} checked={editedTourney.status == 1} onChange={(event) => setEditedTourney({...editedTourney, status: event ? 1 : 0})} title="Статус розыгрыша" /> <span style={{marginLeft: "6px"}}>Статус <b>({editedTourney.status == 1 ? "вкл" : "выкл"})</b></span>
                      </div>

                      <div className={styles.form_fields_item}>
                        <div className={styles.form_fields_item__label}>Только для холдеров</div>
                        <Switch disabled={editedTourney.status == 2} defaultChecked={editedTourney.wlonly == true} checked={editedTourney.wlonly == true} onChange={(event) => setEditedTourney({...editedTourney, wlonly: event ? true : false})} title="Доступ" /> <span style={{marginLeft: "6px"}}>Доступ <b>({editedTourney.wlonly == true ? "Только для холдеров" : "Для всех желающих"})</b></span>
                      </div>
                    </div>

                    {editedTourney.status != 2 ? <div className={styles.action}>
                      {editOrAdd == 0 ? <button className="topracing-btn topracing-btn-red" onClick={() => saveTourney()}>Сохранить</button> : <></>}
                      {editOrAdd == 0 ? <button className="topracing-btn topracing-btn-red" style={{marginLeft: "16px"}} onClick={() => finishTourney()}>Завершить</button> : <></>}
                      {editOrAdd == 0 ? <button className="topracing-btn topracing-btn-redd" style={{marginLeft: "16px"}} onClick={() => deleteTourney(editedTourney)}>Удалить</button> : <></>}
                      {editOrAdd == 1 ? <button className="topracing-btn topracing-btn-red" onClick={() => addNewTourneyAction()}>Создать</button> : <></>}
                    </div> : <></>}
                  </div>
                </div> : <></>}

                </div>
              </div>
            </div>
          </div>
      </div>

      <Modal
        visible={isAddTourneyVisible}
        title="Добавить турнир"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите добавить турнир{editedTourney != undefined ? ` "${editedTourney!.name}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => saveRaffleAction()}>Добавить</button>
          </div>
        </>
        }
        onClose={() => {setIsAddTourneyVisible(false)}}/>

      <Modal
        visible={isEditTourneyVisible}
        title="Обновить турнир"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите обновить турнир{editedTourney != undefined ? ` "${editedTourney!.name}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => saveRaffleAction()}>Обновить</button>
          </div>
        </>
        }
        onClose={() => {setIsEditTourneyVisible(false)}}/>

      <Modal
        visible={isDeleteTourneyVisible}
        title="Удалить турнир"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите удалить турнир{editedTourney != undefined ? ` "${editedTourney!.name}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => deleteTourneyAction()}>Удалить</button>
          </div>
        </>
        }
        onClose={() => {setIsDeleteTourneyVisible(false)}}/>

      <Modal
        visible={isFinishTourneyVisible}
        title="Завершить турнир"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите завершить турнир{editedTourney != undefined ? ` "${editedTourney!.name}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => finishTourneyAction()}>Завершить</button>
          </div>
        </>
        }
        onClose={() => {setIsFinishTourneyVisible(false)}}/>

    </>
  )
}

export default observer(Tourneys)