import React from "react"
import { Route, Routes } from "react-router-dom"
import { privateRoutes, publicRoutes } from "../core/router"

const AppRouter = () => {
  const auth = false;

  return (
    <Routes>
      {publicRoutes.map(route => 
        <Route
          path={route.path} 
          element={route.component}
          key={route.path}
          />
      )}
    </Routes> 
  )
}

export default AppRouter