import { Switch } from "antd";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Context } from "../../..";
import Modal from "../../../components/Modal";
import XTable, { XTableCol } from "../../../components/XTable";
import { ApiService } from "../../../core/api";
import { useFetching } from "../../../core/hooks/useFetching";

import styles from "../../../styles/Mint.module.scss";

interface IMint {
  id?: number;
  name: string;
  drop_index: string;
  cm_key: string;
  buyPrice: string;
  wl_form: boolean;
  status: number;
}

interface ISoldout {
  id?: number;
  name: string;
  description: string;
  price: string;
  soldtime: string;
  status: number;
}

const Mint: FC = () => {

  const { store } = useContext(Context);

  const [currentPage, setCurrentPage] = useState("mintforms" as "soldouts" | "mintforms");

  const [isAddMintVisible, setIsAddMintVisible] = useState(false);
  const [isEditMintVisible, setIsEditMintVisible] = useState(false);
  const [isDeleteMintVisible, setIsDeleteMintVisible] = useState(false);

  const [isAddSoldoutVisible, setIsAddSoldoutVisible] = useState(false);
  const [isEditSoldoutVisible, setIsEditSoldoutVisible] = useState(false);
  const [isDeleteSoldoutVisible, setIsDeleteSoldoutVisible] = useState(false);

  const [soldoutsTable, setSoldoutsTable] = useState([] as XTableCol[][]); // MAIN / 
  const soldoutsThs: string[] = ["ID", "Название", "Описание", "Цена", "Время продажи"];
  
  const [soldoutsList, setSoldoutsList] = useState([] as ISoldout[]);
  const [selectedSoldout, setSelectedSoldout] = useState(undefined as ISoldout | undefined);

  const [newSoldoutName, setNewSoldoutName] = useState("MINT #");
  const [newSoldoutDesc, setNewSoldoutDesc] = useState("120 NFTs");
  const [newSoldoutPrice, setNewSoldoutPrice] = useState("");
  const [newSoldoutSoldtime, setNewSoldoutSoldtime] = useState("");
  const [newSoldoutStatus, setNewSoldoutStatus] = useState(1);

  const [mintsList, setMintsList] = useState([] as IMint[]);
  const [selectedMint, setSelectedMint] = useState(undefined as IMint | undefined);
  
  const [fetchMints, isMintsLoading, errorMints] = useFetching(async () => {
    const res = await ApiService.mints.getMints();
    res.data.mints.sort((a: any, b: any) => {
      return a.id > b.id ? -1 : (a.id < b.id ? 1 : 0);
    });
    setMintsList(res.data.mints);
  });

  const [fetchSoldouts, isSoldoutsLoading, errorSoldouts] = useFetching(async () => {
    const res = await ApiService.soldouts.getSoldouts();
    res.data.soldouts.sort((a: any, b: any) => {
      return a.id > b.id ? -1 : (a.id < b.id ? 1 : 0);
    });
    setSoldoutsList(res.data.soldouts);

    let solds: XTableCol[][] = [];
    res.data.soldouts.forEach((sold: ISoldout) => {
      solds.push([
        {text: sold.id?.toFixed(0)},
        {text: sold.name},
        {text: sold.description},
        {text: sold.price},
        {text: sold.soldtime},
        {showActions: true, showActionsEdit: true, showActionsTrash: true, editAction: () => {
          setSelectedSoldout(sold);
          setNewSoldoutName(sold.name);
          setNewSoldoutDesc(sold.description);
          setNewSoldoutPrice(sold.price);
          setNewSoldoutSoldtime(sold.soldtime);
          setIsEditSoldoutVisible(true);
        }, trashAction: () => {
          setSelectedSoldout(sold);
          setIsDeleteSoldoutVisible(true);
        }}
      ]);
    });
    setSoldoutsTable(solds);

  });

  const selectMint = (mint: IMint) => {
    setSelectedMint(mint);
  }

  const addNewMint = async () => {
    const add = await ApiService.mints.addMint({
      name: "newmintform",
      cm_key: "",
      drop_index: 14,
      buyPrice: "1 SOL",
      wl_form: false,
      status: 0
    });

    fetchMints();
  }

  const saveMint = async () => {
    setIsEditMintVisible(true);
  }

  const saveMintAction = async () => {
    setIsEditMintVisible(false);
    const save = await ApiService.mints.updateMint(selectedMint?.id!, {...selectedMint!, drop_index: parseInt(selectedMint!.drop_index)});
    fetchMints();
    toast("Данные минта успешно обновлены!", {type: 'success'});
  }

  const deleteMint = () => {
    setIsDeleteMintVisible(true);
  }

  const deleteMintAction = async () => {
    setIsDeleteMintVisible(false);
    const del = await ApiService.mints.deleteMint(selectedMint?.id!);
    setSelectedMint(undefined);
    fetchMints();
    toast("Минт-Форма успешно удалена!", {type: 'success'});
  }

  const addSoldoutAction = async () => {
    setIsAddSoldoutVisible(false);

    const add = await ApiService.soldouts.createSoldout({
      name: newSoldoutName,
      description: newSoldoutDesc,
      price: newSoldoutPrice,
      soldtime: newSoldoutSoldtime,
      status: 1
    });

    fetchSoldouts();
  }

  const updateSoldoutAction = async () => {
    setIsEditSoldoutVisible(false);

    const upd = await ApiService.soldouts.updateSoldout(selectedSoldout?.id!, {
      name: newSoldoutName,
      description: newSoldoutDesc,
      price: newSoldoutPrice,
      soldtime: newSoldoutSoldtime,
      status: 1
    });
    setSelectedSoldout(undefined);

    fetchSoldouts();
  }

  const deleteSoldoutAction = async () => {
    setIsDeleteSoldoutVisible(false);
    const del = await ApiService.soldouts.deleteSoldout(selectedSoldout?.id!);
    setSelectedSoldout(undefined);
    fetchSoldouts();
  }

  const addNewSoldout = () => {
    setNewSoldoutName("MINT #");
    setNewSoldoutDesc("120 NFTs");
    setNewSoldoutPrice("");
    setNewSoldoutSoldtime("");
    setIsAddSoldoutVisible(true);
  }

  useEffect(() => {
    fetchMints();
    fetchSoldouts();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="page-header">
            MINTForms <small>Управление</small>
          </h1>

              <div className="card mb-4">
                <ul className="nav nav-tabs nav-tabs-v2 px-4">
                  <li className="nav-item me-3"><a href="#allTab" className={currentPage == "mintforms" ? "nav-link px-2 active" : "nav-link px-2"} data-bs-toggle="tab" onClick={() => setCurrentPage("mintforms")}>Минт-Формы</a></li>
                  <li className="nav-item me-3"><a href="#publishedTab" className={currentPage == "soldouts" ? "nav-link px-2 active" : "nav-link px-2"} data-bs-toggle="tab" onClick={() => setCurrentPage("soldouts")}>История SoldOut'ов</a></li>
                </ul>
                <div className="p-4">

                  {currentPage == "soldouts" ? <div className="row">
                    <div className="col-12 col-md-6">
                      <div className={styles.mints_title} onClick={() => addNewSoldout()}>Добавить запись о солдауте <i className="far fa-plus"></i></div>
                      <XTable
                        isLoading={isSoldoutsLoading}
                        searchVisible={false}
                        searchAction={() => {}}
                        ths={soldoutsThs} 
                        fields={soldoutsTable} />
                    </div>
                  </div> : <></>}

                  {currentPage == "mintforms" ? <div className="row">
                    <div className="col-12 col-md-3">
                      <div className={styles.mints_title} onClick={() => addNewMint()}>Создать <i className="far fa-plus"></i></div>
                      <div className={styles.mints_list}>
                        {mintsList.map((mint: IMint) => <div className={selectedMint?.id == mint.id ? styles.mints_list__item + " " + styles.mints_list__item__selected : styles.mints_list__item} onClick={() => selectMint(mint)}>
                          <div className={styles.mints_list__item_status} style={{backgroundColor: mint.status == 1 ? "#04ba04" : "#d50303"}}></div>
                          <div className={styles.mints_list__item_go}><i className="far fa-arrow-right"></i></div>
                          <div className={styles.mints_list__item_title}>{mint.name}</div>
                          <div className={styles.mints_list__item_sub}>
                            <div className={styles.mints_list__item_price}>{mint.buyPrice}</div>
                            <div className={mint.wl_form ? styles.mints_list__item_type_red : styles.mints_list__item_type}>{mint.wl_form ? "WL Only" : "Public"}</div>
                          </div>
                        </div>)}
                      </div>
                    </div>
                    <div className="col-12 col-md-9">
                      {selectedMint == undefined ? <div className={styles.mints_empty}>Выберите минт-форму для редактирования</div> : <></>}
                      {selectedMint != undefined ? <div className={styles.mint_settings}>
                        <h3>Редактирование минт-формы</h3>
                        <div className="row">
                          <div className="col-12 col-md-3">
                            <div className="form-group mb-2">
                              <label className="form-label">Наименование Минта</label>
                              <div className="input-group">
                                <input type="text" className="form-control" placeholder="Metabuilders" value={selectedMint!.name} onChange={(event) => setSelectedMint({...selectedMint!, name: event.target.value})} />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-3">
                            <div className="form-group mb-2">
                              <label className="form-label">Номер Минта</label>
                              <div className="input-group">
                                <input type="text" className="form-control" placeholder="Номер" value={selectedMint!.drop_index} onChange={(event) => setSelectedMint({...selectedMint!, drop_index: event.target.value})} />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group mb-2">
                              <label className="form-label">Ключ CandyMachine</label>
                              <div className="input-group">
                                <input type="text" className="form-control" placeholder="Ключ CandyMachine" value={selectedMint!.cm_key} onChange={(event) => setSelectedMint({...selectedMint!, cm_key: event.target.value})} />
                              </div>
                            </div>
                          </div>
                          <div className="col-12px col-md-4">
                            <div className="form-group mb-2">
                              <label className="form-label">Цена Минта</label>
                              <div className="input-group">
                                <input type="text" className="form-control" placeholder="1.0" value={selectedMint!.buyPrice} onChange={(event) => setSelectedMint({...selectedMint!, buyPrice: event.target.value})} />
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-4">
                            <div className="form-group mb-2" style={{position: "relative", top: "10px"}}>
                              <label className="form-label">+ WL Форма</label>
                              <div className="">
                                <Switch checked={selectedMint!.wl_form} onChange={(event) => setSelectedMint({...selectedMint!, wl_form: event})} /> <span>{selectedMint!.wl_form ? "Вкл" : "Выкл"}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-4">
                            <div className="form-group mb-2" style={{position: "relative", top: "10px"}}>
                              <label className="form-label">Статус</label>
                              <div className="">
                                <Switch checked={selectedMint!.status == 1} onChange={(event) => setSelectedMint({...selectedMint!, status: event ? 1 : 0})} /> <span>{selectedMint!.status == 1 ? "Вкл" : "Выкл"}</span>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-12" style={{
                            paddingTop: "12px"
                          }}>
                            <button className={styles.mint_save} onClick={() => saveMint()}>Сохранить</button>
                            <button className={styles.mint_delete} style={{marginLeft: "16px"}} onClick={() => deleteMint()}>Удалить</button>
                          </div>

                        </div>
                      </div> : <></>}
                    </div>
                  </div> : <></>}

                </div>
              </div>
            </div>
      </div>

      <Modal
        visible={isEditMintVisible}
        title="Обновить Минт-форму"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите обновить информацию о минт-форме?
          </div>

          <div className="modal_action">
            <button onClick={() => saveMintAction()}>Обновить</button>
          </div>
        </>
        }
        onClose={() => {setIsEditMintVisible(false)}}/>

      <Modal
        visible={isDeleteMintVisible}
        title="Удалить Минт-форму"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите удалить эту минт-форму?
          </div>

          <div className="modal_action">
            <button onClick={() => deleteMintAction()}>Удалить</button>
          </div>
        </>
        }
        onClose={() => {setIsDeleteMintVisible(false)}}/>


      <Modal
        visible={isAddSoldoutVisible}
        title="Добавить запись о солдауте"
        maxWidth="520px"
        content={
        <>
          <div className={styles.form_fields}>
            <div className={styles.form_fields_item}>
              <input type="text" placeholder="Наименование минта" value={newSoldoutName} onChange={(event) => setNewSoldoutName(event.target.value) } />
            </div>
            <div className={styles.form_fields_item}>
              <input type="text" placeholder="Описание солдаута" value={newSoldoutDesc} onChange={(event) => setNewSoldoutDesc(event.target.value) } />
            </div>
            <div className={styles.form_fields_item}>
              <input type="text" placeholder="Цена минта" value={newSoldoutPrice} onChange={(event) => setNewSoldoutPrice(event.target.value) } />
            </div>
            <div className={styles.form_fields_item}>
              <input type="text" placeholder="Время солдаута" value={newSoldoutSoldtime} onChange={(event) => setNewSoldoutSoldtime(event.target.value) } />
            </div>
          </div>

          <div className="modal_action">
            <button onClick={() => addSoldoutAction()}>Добавить</button>
          </div>
        </>
        }
        onClose={() => {setIsAddSoldoutVisible(false)}}/>

      <Modal
        visible={isEditSoldoutVisible}
        title="Обновить запись о солдауте"
        maxWidth="520px"
        content={
        <>
          <div className={styles.form_fields}>
            <div className={styles.form_fields_item}>
              <input type="text" placeholder="Наименование минта" value={newSoldoutName} onChange={(event) => setNewSoldoutName(event.target.value) } />
            </div>
            <div className={styles.form_fields_item}>
              <input type="text" placeholder="Описание солдаута" value={newSoldoutDesc} onChange={(event) => setNewSoldoutDesc(event.target.value) } />
            </div>
            <div className={styles.form_fields_item}>
              <input type="text" placeholder="Цена минта" value={newSoldoutPrice} onChange={(event) => setNewSoldoutPrice(event.target.value) } />
            </div>
            <div className={styles.form_fields_item}>
              <input type="text" placeholder="Время солдаута" value={newSoldoutSoldtime} onChange={(event) => setNewSoldoutSoldtime(event.target.value) } />
            </div>
          </div>

          <div className="modal_action">
            <button onClick={() => updateSoldoutAction()}>Обновить</button>
          </div>
        </>
        }
        onClose={() => {setIsEditSoldoutVisible(false)}}/>

      <Modal
        visible={isDeleteSoldoutVisible}
        title="Удалить запись о солдауте"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите удалить запись о солдауте "{selectedSoldout?.name}"?
          </div>

          <div className="modal_action">
            <button onClick={() => deleteSoldoutAction()}>Удалить</button>
          </div>
        </>
        }
        onClose={() => {setIsDeleteSoldoutVisible(false)}}/>
    </>
  )
}

export default observer(Mint)
