import { FC, useContext, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Context } from "../../..";
import XTable, { XTableCol } from "../../../components/XTable";
import { useFetching } from "../../../core/hooks/useFetching";
import { ApiService } from "../../../core/api";
import { IUser } from "../../../core/models/User";
import { isNullOrUndefined } from "../../../core/utils";

const Payments: FC = () => {

  const { store } = useContext(Context);

  const [searchText, setSearchText] = useState(""); // Wallet / Name / Surname
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);
  const [paymentsList, setPaymentsList] = useState([] as IUser[]);

  const [paymentsTable, setPaymentsTable] = useState([] as XTableCol[][]); // MAIN / 
  const paymentsThs: string[] = ["ID", "Пользователь", "Сумма", "Дата"];
  
  const [fetchUsers, isPaymentsLoading, errorPayments] = useFetching(async () => {
    const res = await ApiService.users.getPayments({ page: pageIndex, limit: 10, search: searchText });

    setTotalPages(res.data.totalPages);
    setTotalPayments(res.data.total);

    let usrs: XTableCol[][] = [];
    res.data.payments.forEach((payment: any) => {
      usrs.push([
        {text: payment.id},
        {text: `${payment.user.login.substr(0, 4)}...${payment.user.login.substr(payment.user.login.length - 4, payment.user.login.length)}, ${payment.user.name}, ${payment.user.surname}`},
        {text: payment.sum},
        {text: payment.createdAt}
      ]);
    });

    setPaymentsTable(usrs);

    console.log(res.data);
    console.log(usrs);
  });

  useEffect(() => {
    fetchUsers();
  }, [searchText]);

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="page-header">
            Платежи <small>Управление</small>
          </h1>

          <div className="card mb-4">
            <div className="p-4">
              <div className="row">
                <div className="col-12 col-md-6">
                  <XTable
                    isLoading={isPaymentsLoading}
                    searchVisible={false}
                    searchAction={() => {}}
                    ths={paymentsThs} 
                    fields={paymentsTable} />
                    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Payments)