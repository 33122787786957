import axios, { AxiosResponse } from "axios";
import { API_URL } from "../defaults";
import { isNullOrUndefined } from "../utils";

const $api = axios.create({
  baseURL: API_URL
});

$api.interceptors.request.use((config) => {
  config.headers!["Authorization"] = `Bearer super_api_key`;
  return config;
});

$api.interceptors.response.use((response: AxiosResponse) => {
  return response;
}, async (error: any) => {
  console.log("INTERCEPT");
  console.log(error.response.data.message);
  return error;
});

export default $api;