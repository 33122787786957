import { FC, useContext, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Context } from "../../..";
import {useFetching} from "../../../core/hooks/useFetching";
import {ApiService} from "../../../core/api";

import styles from "../../../styles/Drops.module.scss";
import XTable, {XTableCol} from "../../../components/XTable";
import {isNullOrUndefined} from "../../../core/utils";
import {PlusOutlined, RedoOutlined, SearchOutlined, UploadOutlined} from "@ant-design/icons";
import Modal from "../../../components/Modal";

const Drops: FC = () => {

  const { store } = useContext(Context);

  const [dropsTable, setDropsTable] = useState([] as XTableCol[][]); // MAIN / 
  const dropsThs: string[] = ["Картинка", "Адрес", "Дроп", "Семья", "Вселенная", "Мультиплай", "DSI", "Холдер"];
  
  const [allDrops, setAllDrops] = useState([] as any[]);
  const [filteredItems, setFilteredItems] = useState([] as any[]);
  const [selectedDrop, setSelectedDrop] = useState("1");
  const [universes, setUniverses] = useState([] as any[]);
  const [hashesForUpload, setHashesForUpload] = useState([] as string[]);
  const [newHashes, setNewHashes] = useState("");
  const [newDropNum, setNewDropNum] = useState("");

  const [isAddDropVisible, setIsAddDropVisible] = useState(false);
  const [isExportDropVisible, setIsExportDropVisible] = useState(false);
  const [isExportUnivVisible, setIsExportUnivVisible] = useState(false);
  const [isRevealDropVisible, setIsRevealDropVisible] = useState(false);
  const [isRechargeDropVisible, setIsRechargeDropVisible] = useState(false);

  const [fetchDrops, isDropsLoading, errorDrops] = useFetching(async () => {
    const res = await ApiService.drops.getAllDrops();
    const univs = await ApiService.drops.getUniverses();

    console.log(res);
    console.log(univs);

    let lst: any[] = [];
    for (let key in res.data) {
      console.log(key);
      if (key.includes("drop")) {
        let items = res.data[key];
        lst.push({
          key: key,
          drop: res.data[key]
        });
      }
    }

    let compiled: any[] = [];
    lst.forEach((item: any, ix: number) => {
      let form = {
        name: `Дроп ${ix + 1}`,
        key: item.key,
        index: ix + 1,
        items: [] as any[]
      };
      item.drop.forEach((item2: any) => {
        item2.items.forEach((item3: any) => {
          item3.items.forEach((item4: any) => {
            form.items.push(item4);
          });
        });
      });
      compiled.push(form);
    });

    console.log(compiled);

    setSelectedDrop("1");

    let table: XTableCol[][] = [];

    let drop: any = compiled.find(v => v.key == "drop1");
    drop.items.forEach((nft: any) => {
      const attrDrop = nft.enque_data.attributes.find((attr: any) => attr.trait_type == "Drop");
      const attrFamily = nft.enque_data.attributes.find((attr: any) => attr.trait_type == "Family");
      const attrMulti = nft.enque_data.attributes.find((attr: any) => attr.trait_type == "Multiplier");
      const attrUniv = nft.enque_data.attributes.find((attr: any) => attr.trait_type == "Universe");
      const attrDSI = nft.enque_data.attributes.find((attr: any) => attr.trait_type == "Daily staking income");

      table.push([
        {isImage: true, text: nft.image},
        {text: nft.address},
        {text: attrDrop.value},
        {text: attrFamily.value},
        {text: attrUniv != undefined ? attrUniv.value : "-------"},
        {text: attrMulti != undefined ? attrMulti.value : "-------"},
        {text: attrDSI != undefined ? attrDSI.value : "-------"},
        {text: !isNullOrUndefined(nft.user) ? nft.user.login : "-------"}
      ]);
    });

    setDropsTable(prev => prev = table);

    setAllDrops(prev => prev = compiled);
    setUniverses(prev => prev = univs.data.universes);
  });

  const addDropAction = async () => {

  }

  const revealDropAction = async () => {}
  const rechargeHoldersAction = async () => {}

  const selectDrop = async (drop: any) => {
    setSelectedDrop(`${drop.index}`);
    let table: XTableCol[][] = [];

    drop.items.forEach((nft: any) => {
      const attrDrop = nft.enque_data.attributes.find((attr: any) => attr.trait_type == "Drop");
      const attrFamily = nft.enque_data.attributes.find((attr: any) => attr.trait_type == "Family");
      const attrMulti = nft.enque_data.attributes.find((attr: any) => attr.trait_type == "Multiplier");
      const attrUniv = nft.enque_data.attributes.find((attr: any) => attr.trait_type == "Universe");
      const attrDSI = nft.enque_data.attributes.find((attr: any) => attr.trait_type == "Daily staking income");

      table.push([
        {isImage: true, text: nft.image},
        {text: nft.address},
        {text: attrDrop.value},
        {text: attrFamily.value},
        {text: attrUniv != undefined ? attrUniv.value : "-------"},
        {text: attrMulti != undefined ? attrMulti.value : "-------"},
        {text: attrDSI != undefined ? attrDSI.value : "-------"},
        {text: !isNullOrUndefined(nft.user) ? nft.user.login : "-------"}
      ]);
    });

    setDropsTable(prev => prev = table);
  }

  useEffect(() => {
    fetchDrops();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="page-header">
            Дропы <small>Управление</small>
          </h1>

          <div className="card mb-4">
            <div className="p-4">
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className={styles.drops_tabs}>
                    {allDrops.map(drop => <div className={styles.drops_tabs__item + (selectedDrop == `${drop.index}` ? " " + styles.active : "")} onClick={() => selectDrop(drop)}>{drop.name}</div>)}
                    <div className={styles.drops_tabs__item} onClick={() => setIsAddDropVisible(true)}>Коллекции</div>
                    <div className={styles.drops_tabs__item} onClick={() => setIsAddDropVisible(true)}><PlusOutlined /> Добавить дроп</div>
                  </div>

                  <div className={styles.drops_title}>Дроп {selectedDrop}</div>
                  <div className={styles.drops_actions}>
                    <div className={styles.drops_acts__search}>
                      <div>Поиск</div>
                      <div><input type="text" placeholder="Поиск по адресу / холдеру / семье / вселенной" /></div>
                    </div>
                    <div className={styles.drops_acts__btns}>
                      <div className={styles.flexbtns}>
                        <div onClick={() => setIsExportDropVisible(true)}><UploadOutlined /> Выгрузить в XLSX</div>
                        <div onClick={() => setIsRechargeDropVisible(true)}><SearchOutlined /> Поиск холдеров</div>
                        <div onClick={() => setIsRevealDropVisible(true)}><RedoOutlined /> Ревил дропа</div>
                      </div>
                    </div>
                  </div>
                  
                  <div className={styles.drop_table}>
                    <XTable 
                      isLoading={isDropsLoading}
                      searchVisible={false}
                      searchAction={() => {}}
                      ths={dropsThs} 
                      fields={dropsTable} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={isAddDropVisible}
        title="Добавить дроп"
        onClose={() => setIsAddDropVisible(false)}
        content={<>
          <div className="modal_text">
            Добавить дроп
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => setIsAddDropVisible(false)}>Закрыть</button>
              <button onClick={() => addDropAction()}>Добавить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isExportDropVisible || isExportUnivVisible}
        title={`Экспортировать ${isExportDropVisible ? "дроп" : "коллекции"}`}
        onClose={() => isExportDropVisible ? setIsExportDropVisible(false) : setIsExportUnivVisible(false)}
        content={<>
          <div className="modal_text">
            
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => isExportDropVisible ? setIsExportDropVisible(false) : setIsExportUnivVisible(false)}>Закрыть</button>
              <button onClick={() => addDropAction()}>Выгрузить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isRevealDropVisible}
        title="Ревил дропа"
        onClose={() => setIsRevealDropVisible(false)}
        content={<>
          <div className="modal_text">
            Ревил дропа
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => setIsRevealDropVisible(false)}>Закрыть</button>
              <button onClick={() => revealDropAction()}>Ревильнуть</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isRechargeDropVisible}
        title="Запустить поиск холдеров"
        onClose={() => setIsRechargeDropVisible(false)}
        content={<>
          <div className="modal_text">
            Запустить поиск холдеров
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => setIsRechargeDropVisible(false)}>Закрыть</button>
              <button onClick={() => rechargeHoldersAction()}>Добавить</button>
            </div>
          </div>
        </>} />
    </>
  )
}

export default observer(Drops)