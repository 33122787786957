import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import "moment/locale/ru";
import moment from "moment";
import Player from './core/components/Player';
import Store from './core/store';

moment.locale("ru");

interface State {
  store: Store;
}

const store = new Store();

export const Context = createContext<State>({store});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Context.Provider value={{store}}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Context.Provider>
);
