import { FC, useContext, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Context } from "../../..";
import XTable, { XTableCol } from "../../../components/XTable";
import { useFetching } from "../../../core/hooks/useFetching";
import { ApiService } from "../../../core/api";
import { IUser } from "../../../core/models/User";
import { isNullOrUndefined } from "../../../core/utils";
import { DebounceInput } from "react-debounce-input";
import Modal from "../../../components/Modal";

import styles from "../../../styles/Users.module.scss";
import { Select } from "antd";
import { Option } from "antd/lib/mentions";

import * as XLSX from "xlsx";
import moment from "moment";

const Users: FC = () => {

  const { store } = useContext(Context);

  const [filterDrops, setFilterDrops] = useState([
    {value: "all", name: "Все", nameEn: "All"},
    {value: "1", name: "1", nameEn: "1"},
    {value: "2", name: "2", nameEn: "2"},
    {value: "3", name: "3", nameEn: "3"},
    {value: "4", name: "4", nameEn: "4"},
    {value: "5", name: "5", nameEn: "5"},
    {value: "6", name: "6", nameEn: "6"},
  ] as any[]);

  const [filterFamily, setFilterFamily] = useState([
    {value: "all", name: "Все", nameEn: "All"},
    {value: "joker", name: "Джокеры", nameEn: "Joker"},
    {value: "foxes", name: "Лисы", nameEn: "Foxes"},
    {value: "hippos", name: "Бегемоты", nameEn: "Hippos"},
    {value: "rhinos", name: "Носороги", nameEn: "Rhinos"},
    {value: "bears", name: "Медведи", nameEn: "The Bears"},
    {value: "tigers", name: "Тигры", nameEn: "Tigers"},
    {value: "wolves", name: "Волки", nameEn: "Wolves"},
    {value: "lions", name: "Львы", nameEn: "Lions"},
    {value: "elephants", name: "Слоны", nameEn: "Elephants"},
    {value: "turtles", name: "Черепахи", nameEn: "Turtles"},
    {value: "boars", name: "Кабаны", nameEn: "Boars"},
    {value: "bulls", name: "Быки", nameEn: "Bulls"},
    {value: "monkeys", name: "Обезьяны", nameEn: "Monkeys"}
  ] as any[]);
  const [filterUniverse, setFilterUniverse] = useState([
    {value: "all", name: "Все", nameEn: "All"}
  ] as any[]);

  const [filterDropsValue, setFilterDropsValue] = useState("all");
  const [filterFamilyValue, setFilterFamilyValue] = useState("all");
  const [filterUniverseValue, setFilterUniverseValue] = useState("all");

  const [searchText, setSearchText] = useState(""); // Wallet / Name / Telegram
  const [searchType, setSearchType] = useState("ALL"); // ALL / UNSUB / SUB / MODER / ADMIN 
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [usersList, setUsersList] = useState([] as IUser[]);
  const [usersAllList, setUsersAllList] = useState([] as IUser[]);

  const [selectedUser, setSelectedUser] = useState(undefined as IUser | undefined);
  const [isUserDeleteVisible, setIsUserDeleteVisible] = useState(false);
  const [isUserEditVisible, setIsUserEditVisible] = useState(false);

  const [usersAll, setUsersAll] = useState([] as any[]); // MAIN / 
  const [usersTable, setUsersTable] = useState([] as XTableCol[][]); // MAIN / 
  const usersThs: string[] = ["ID", "Кошелек", "Имя", "Телеграм", "Кол-во NFT", "Действия"];
  
  const [fetchUsers, isUsersLoading, errorUsers] = useFetching(async () => {
    const res = await ApiService.users.getUsers({
      page: pageIndex, limit: 10, search: searchText
    });

    setTotalPages(res.data.totalPages);
    setTotalUsers(res.data.total);
    setUsersAll(res.data.usersAll);

    let usrs: XTableCol[][] = [];
    res.data.users.forEach((user: any) => {
      usrs.push([
        {text: user.id},
        {text: `${user.login.substr(0, 4)}...${user.login.substr(user.login.length - 4, user.login.length)}`},
        {text: user.name},
        {text: user.telegram},
        {text: !isNullOrUndefined(user.collection) ? user.collection.nfts.length : 0},
        {showActions: true, showActionsEdit: true, showActionsTrash: true, editAction: () => {
          setSelectedUser(user);
          setIsUserEditVisible(true);
          
        }, trashAction: () => {
          setSelectedUser(user);
          setIsUserDeleteVisible(true);
        }}
      ]);
    });

    setUsersTable(usrs);

    console.log(res.data);
    console.log(usrs);
  });

  useEffect(() => {
    getFilteredUserNfts();
  }, [selectedUser]);

  const deleteUserAction = async () => {
    const res = await ApiService.users.deleteUser(selectedUser?.id!);

    setIsUserDeleteVisible(false);
    setSelectedUser(undefined);

    fetchUsers();
  }

  const updateFilter = (name: string, value: string) => {
    if (name == "drop") {
      setFilterDropsValue(value);
    }
    if (name == "family") {
      setFilterFamilyValue(value);
    }
    if (name == "universe") {
      setFilterUniverseValue(value);
    }
  }

  const prevPage = () => pageIndex > 0 ? setPageIndex(pageIndex - 1) : false;
  const nextPage = () => pageIndex < totalPages ? setPageIndex(pageIndex + 1) : false;

  const exportToXls = () => {
    let json: any[] = [];

    usersAll.forEach(user => {
      json.push({
        "Кошелек": user.login,
        "Имя": user.name,
        "Кол-во NFT": !isNullOrUndefined(user.collection) ? user.collection.nfts.length : 0
      });
    });

    json.sort((a, b) => {
      if (a["Кол-во NFT"] > b["Кол-во NFT"]) {
        return -1;
      } else if (a["Кол-во NFT"] < b["Кол-во NFT"]) {
        return 1;
      } else {
        return 0;
      }
    });

    const worksheet = XLSX.utils.json_to_sheet(json);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Пользователи");
    const now = moment();
    XLSX.writeFile(workbook, `UsersTable_${now.unix()}.xlsx`);
  }

  const [filteredUserNfts, setFilteredUserNfts] = useState([] as any[]);
  const [getFilteredUserNfts] = useFetching(async () => {
    if (!isNullOrUndefined(selectedUser?.collection)) {
      let arr = [...selectedUser!.collection.nfts];
      console.log("arr");
      console.log(arr);

      if (filterDropsValue != "all") {
        arr = arr.filter(v => v.enque_data.attributes[0].value == filterDropsValue);
      }

      if (filterFamilyValue != "all") {
        arr = arr.filter(v => v.enque_data.attributes[1].value.toLowerCase() == filterFamilyValue);
      }

      if (filterUniverseValue != "all") {
        arr = arr.filter(v => v.enque_data.attributes[4].value == filterUniverseValue);
      }

      setFilteredUserNfts(prev => prev = arr);
    } else {
      setFilteredUserNfts([]);
    }
  })

  useEffect(() => {
    console.log(filteredUserNfts);
  }, [filteredUserNfts]);

  useEffect(() => {
    getFilteredUserNfts();
  }, [filterDropsValue, filterFamilyValue, filterUniverseValue]);

  useEffect(() => {fetchUsers()}, [searchText, pageIndex]);

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="page-header">
            Пользователи <small>Управление</small>
          </h1>

          <div className="card mb-4">
            <div className="p-4">
              <div className="row">
                <div className="col-12 col-md-6">

                <a onClick={() => exportToXls()} className="btn btn-primary" style={{marginBottom: "16px"}}>Выгрузить в XLS</a>
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <DebounceInput
                          className="form-control ps-35px"
                          minLength={2}
                          type="text"
                          placeholder="Поиск по кошельку, имени, телеграму..."
                          debounceTimeout={800}
                          onChange={event => setSearchText(event.target.value)}
                          />
                        <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0">
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <XTable
                    isLoading={isUsersLoading}
                    searchVisible={false}
                    searchAction={() => {}}
                    ths={usersThs} 
                    fields={usersTable} />
                  
                  <div className="d-md-flex align-items-center">
                    <div className="me-md-auto text-md-left text-center mb-2 mb-md-0" style={{color: "#fff"}}>
                      Показано с 1 по {usersList.length} из {totalUsers} записей
                    </div>
                    <ul className="pagination mb-0 justify-content-center">
                      <li className="page-item"><a className="page-link" onClick={() => prevPage()}><i className="fas fa-chevron-left"></i></a></li>
                      <li className="page-item active"><a className="page-link" href="#">{pageIndex + 1} страница из {totalPages + 1}</a></li>
                      <li className="page-item"><a className="page-link" onClick={() => nextPage()}><i className="fas fa-chevron-right"></i></a></li>
                    </ul>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  {isUserEditVisible == false ? <div className={styles.user_not_selected}>Выберите пользователя для просмотра</div> : <></>}

                  {selectedUser != undefined && isUserEditVisible == true ? <div className={styles.account_details}>
                    <div className={styles.account_details__avatar}>
                      <div style={{backgroundImage: "url(" + selectedUser.avatar + ")"}}></div>
                    </div>
                    <div className={styles.account_details__name}>{selectedUser.name}</div>
                    <div className={styles.account_details__telegram}><i className="fab fa-telegram"></i> {selectedUser.surname}</div>
                    <div className={styles.account_details__pubkey}>{selectedUser.login.substring(0, 4)}...{selectedUser.login.substring(selectedUser.login.length - 4, selectedUser.login.length)}</div>
                    
                    {<div className={styles.account_details__collection_title}>{!isNullOrUndefined(selectedUser.collection) ? selectedUser.collection.nfts.length : 0} NFT</div>}

                    <div className={styles.collections_filters}>
                      <div className={styles.collections_filters__item}>
                        <div className={styles.collections_filters__item_title}>Дроп</div>
                        <Select defaultValue={filterDropsValue} style={{ width: "100%" }} onChange={(event) => {updateFilter("drop", event)}}>
                          {filterDrops.map(item => <Option value={item.value}>{item.name}</Option>)}
                        </Select>
                      </div>
                      <div className={styles.collections_filters__item}>
                        <div className={styles.collections_filters__item_title}>Семья</div>
                        <Select defaultValue={filterFamilyValue} style={{ width: "100%" }} onChange={(event) => {updateFilter("family", event)}}>
                          {filterFamily.map(item => <Option value={item.value}>{item.name}</Option>)}
                        </Select>
                      </div>
                      <div className={styles.collections_filters__item}>
                        <div className={styles.collections_filters__item_title}>Коллекция</div>
                        <Select defaultValue={filterUniverseValue} style={{ width: "100%" }} onChange={(event) => {updateFilter("universe", event)}}>
                          {filterUniverse.map(item => <Option value={item.value}>{item.name}</Option>)}
                        </Select>
                      </div>
                    </div>

                    {!isNullOrUndefined(selectedUser.collection) ? <div className={styles.account_details__collection}>
                      {filteredUserNfts.map((nftItem: any) => <div className={styles.account_details__collection_item}>
                        <div className={styles.account_details__collection_item__img}><img src={nftItem.enque_data.image} /></div>
                        <div className={styles.account_details__collection_item__attrs}>
                          {nftItem.enque_data.attributes.map((attr: any) => <div className={styles.account_details__collection_item__attrs_item}>
                            <div>{attr.trait_type}</div><div>{attr.value}</div>
                          </div>)}
                        </div>
                      </div>)}
                    </div> : <></>}
                  </div> : <></>}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={isUserDeleteVisible}
        title="Удалить пользователя"
        maxWidth="420px"
        content={
          <>
            <div className="modal_text">
              Вы действительно хотите удалить пользователя {selectedUser?.name} из системы?
            </div>
            <div className="modal_action">
              <div>
                <button onClick={() => setIsUserDeleteVisible(false)}>Закрыть</button>
                <button onClick={() => deleteUserAction}>Подтвердить</button>
              </div>
            </div>
          </>
        }
        onClose={() => {setIsUserDeleteVisible(false)}}/>
    </>
  )
}

export default observer(Users)