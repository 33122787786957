import moment from "moment";
import { API_URL } from "./defaults";
import { ControllerName } from "./types"

export const getDateFormat = (date: string, format: string = "DD MMM YYYY, HH:mm") => {
  return moment(date).format(format);
}

export async function delay(duration: number, callback?: any) {
  return await new Promise((resolve, reject) => {
    setTimeout(() => {
      if (!isNullOrUndefined(callback)) {
        callback();
      }
      resolve(true);
    }, duration);
  });
}

export const buildUrl = (
  controller: ControllerName,
  method: string
) => {
  return `${API_URL}${controller}/${method}`
}

export const buildParams = (map: any) => {
  let query = "";
  for (let key in map) {
    if (query == "") {
      query = `?${key}=${map[key]}`;
    } else {
      query += `&${key}=${map[key]}`;
    }
  }
  return query;
}

export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
  return typeof obj === "undefined" || obj === null;
}

export async function asyncForEach(array: any[], callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function declOfNum(n: number, text_forms: string[]) {  
  n = Math.abs(n) % 100; 
  var n1 = n % 10;
  if (n > 10 && n < 20) { return text_forms[2]; }
  if (n1 > 1 && n1 < 5) { return text_forms[1]; }
  if (n1 == 1) { return text_forms[0]; }
  return text_forms[2];
}