import { AxiosResponse } from "axios";
import $api from "../http";
import { ControllerName } from "../types";
import { buildUrl } from "../utils";

export default class AuthService {
  public static async signIn(login: string, password: string): Promise<AxiosResponse> {
    return $api.post(buildUrl(ControllerName.USERS, `signin`), {
      login, email: login, password
    });
  }
  public static async signUp(name: string, email: string, password: string): Promise<AxiosResponse> {
    return $api.post(buildUrl(ControllerName.USERS, `signup`), {
      name, email, password, login: email
    });
  }
}