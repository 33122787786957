import { PlusOutlined } from "@ant-design/icons";
import { Switch, UploadProps } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Context } from "../../..";
import Modal from "../../../components/Modal";
import XTable, { XTableCol } from "../../../components/XTable";
import { ApiService } from "../../../core/api";
import { API_URL } from "../../../core/defaults";
import { useFetching } from "../../../core/hooks/useFetching";
import { IRaffle } from "../../../core/models/Raffle";
import { IRaffleTicket } from "../../../core/models/RaffleTicket";
import { isNullOrUndefined } from "../../../core/utils";

import * as XLSX from "xlsx";

import styles from "../../../styles/Raffles.module.scss";

const Raffles: FC = () => {
  let inter: any = null;

  const { store } = useContext(Context);

  const [rafflesList, setRafflesList] = useState([] as IRaffle[]);

  const [editedRaffle, setEditedRaffle] = useState(undefined as IRaffle | undefined);
  const [editOrAdd, setEditOrAdd] = useState(0);

  const [isAddRaffleVisible, setIsAddRaffleVisible] = useState(false);
  const [isEditRaffleVisible, setIsEditRaffleVisible] = useState(false);
  const [isDeleteRaffleVisible, setIsDeleteRaffleVisible] = useState(false);
  const [isPauseRaffleVisible, setIsPauseRaffleVisible] = useState(false);
  const [isFinishRaffleVisible, setIsFinishRaffleVisible] = useState(false);

  const [ticketsOwnersTable, setTicketsOwnersTable] = useState([] as XTableCol[][]); // MAIN / 
  const ticketsOwnersThs: string[] = ["Логин", "Адрес", "Кол-во", "Сумма", "Бонусные", "Всего", "Кол-во победных"];  

  const props: UploadProps = {
    name: 'image', multiple: false, maxCount: 1, showUploadList: true,
    headers: {"Authorization": "Bearer " + store.user.token},
    action: `${API_URL}/mbc/customize/upload/`,
    onChange: async (info) => {
      const { status } = info.file;
      if (status === 'done') {
        toast("Изображение успешно загружено!", {type: 'success'});
        let path = "https://" + info.file.response.save.split("www-root/data/www/")[1];
        console.log(path);
        setEditedRaffle({
          ...editedRaffle, banner: path
        });
        console.log(`${info.file.name} file uploaded successfully.`);
      } else {
        console.log(status);
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    onDrop(e) {console.log('Dropped files', e.dataTransfer.files);},
  };

  const [fetchRaffles, isRafflesLoading, errorRaffles] = useFetching(async () => {
    const res = await ApiService.raffles.getRaffles();
    console.log(res.data);

    res.data.raffles.sort((a: any, b: any) => {
      return a.id > b.id ? -1 : (a.id < b.id ? 1 : 0);
    });

    setRafflesList(res.data.raffles);

    if (editedRaffle !== undefined) {
      setEditedRaffle(res.data.raffles.find((v: any) => v.id == editedRaffle.id));
    }
  });

  const addNewRaffle = () => {
    setEditedRaffle({
      banner: "",
      title: "",
      description: "",
      ticketPrice: "1",
      maxTicketsCount: 1,
      maxWalletTicketsCount: 1,
      status: 1,
      statushh: 0,
      hhDate: 0,
      hhDaysLong: 0
    });
    setEditOrAdd(1);
  }

  const editRaffle = (item: IRaffle) => {
    setEditOrAdd(0);
    setEditedRaffle(item);

    if (item.status == 2) {
      let soldsWinners: XTableCol[][] = [];
      let solds: XTableCol[][] = [];
      let tcksByUser: any[] = [];
      item.tickets!.forEach((ticket: IRaffleTicket) => {
        if (tcksByUser.find(v => v.user.id == ticket.author.id) == undefined) {
          let newTick = {
            user: ticket.author,
            tickets: [] as IRaffleTicket[]
          };
          newTick.tickets.push(ticket);
          tcksByUser.push(newTick);
        } else {
          const ix = tcksByUser.findIndex(v => v.user.id == ticket.author.id);
          tcksByUser[ix].tickets.push(ticket);
        }
      });

      console.log(tcksByUser);

      tcksByUser.forEach((ticketsByUser) => {
        const isBadge = item.results!.find(result => result.ticket.ticket.author.id == ticketsByUser.user.id) != undefined;
        const isBadgeCounts = item.results!.filter(result => result.ticket.ticket.author.id == ticketsByUser.user.id);
        const isBadgeCount = isBadgeCounts != undefined ? isBadgeCounts.length : 0;
        const data = [
          {text: ticketsByUser.user.name, isBadge: isBadge, badgeState: isBadge},
          {text: ticketsByUser.user.login},
          {text: ticketsByUser.tickets.filter((v: any) => v.ticketValue == "1").length + ticketsByUser.tickets.filter((v: any) => v.ticketValue == "3").length},
          {text: `${ticketsByUser.tickets.filter((v: any) => v.ticketValue == "1").length * parseFloat(item.ticketPrice!)} SOL`},
          {text: ticketsByUser.tickets.filter((v: any) => v.ticketValue == "2").length},
          {text: ticketsByUser.tickets.filter((v: any) => v.ticketValue == "1").length + ticketsByUser.tickets.filter((v: any) => v.ticketValue == "2").length + ticketsByUser.tickets.filter((v: any) => v.ticketValue == "3").length},
          {text: isBadgeCount},
        ];
        
        if (isBadge) {
          soldsWinners.push(data);
        } else {
          solds.push(data);
        }
      });

      solds.sort((a: any, b: any) => {
        return parseInt(a[5].text) > parseInt(b[5].text) ? -1 : (parseInt(a[5].text) < parseInt(b[5].text) ? 1 : 0);
      });

      solds = [...soldsWinners, ...solds];

      setTicketsOwnersTable(solds);
    } else {
      setTicketsOwnersTable([]);
    }

    getRaffleTiming();
  }

  const exportToXls = () => {
    let json: any[] = [];

    editedRaffle?.results!.forEach(result => {
      json.push({
        "Кошелек": result.ticket.ticket.author.login,
        "Имя": result.ticket.ticket.author.name,
        "Победный билет": `№${result.ticket.ticket.id}`,
        "Куплено билетов": editedRaffle.tickets?.filter(v => v.userId == result.ticket.ticket.author.id && v.ticketValue == "1").length! + editedRaffle.tickets?.filter(v => v.userId == result.ticket.ticket.author.id && v.ticketValue == "3").length!,
        "Бонусные": editedRaffle.tickets?.filter(v => v.userId == result.ticket.ticket.author.id && v.ticketValue == "2").length,
        "Всего": editedRaffle.tickets?.filter(v => v.userId == result.ticket.ticket.author.id).length
      });
    });

    json.sort((a, b) => {
      if (a["Всего"] > b["Всего"]) {
        return -1;
      } else if (a["Всего"] < b["Всего"]) {
        return 1;
      } else {
        return 0;
      }
    });

    const worksheet = XLSX.utils.json_to_sheet(json);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Розыгрыш #${editedRaffle?.id}`);
    const now = moment();
    XLSX.writeFile(workbook, `Raffle_${editedRaffle?.id}_results.xlsx`);
  }

  const deleteRaffle = (item: IRaffle) => {
    setEditedRaffle(item);
    setIsDeleteRaffleVisible(true);
  }

  const deleteRaffleAction = async () => {
    setIsDeleteRaffleVisible(false);
    setEditedRaffle(undefined);
    const res = await ApiService.raffles.deleteRaffle(editedRaffle?.id!);
    fetchRaffles();
    toast("Розыгрыш успешно удален!", {type: "success"});
  }

  const saveRaffle = () => {
    setIsEditRaffleVisible(true);
  }

  const saveRaffleAction = async () => {
    setIsEditRaffleVisible(false);

    const res = await ApiService.raffles.updateRaffle(editedRaffle?.id!, {
      banner: editedRaffle?.banner,
      title: editedRaffle?.title,
      description: editedRaffle?.description,
      ticketPrice: editedRaffle?.ticketPrice,
      maxTicketsCount: editedRaffle?.maxTicketsCount,
      maxWalletTicketsCount: editedRaffle?.maxWalletTicketsCount,
      multiplicityByNft: editedRaffle?.multiplicityByNft,
      wlTokensCount: editedRaffle?.wlTokensCount,
      status: editedRaffle?.status,
      statushh: editedRaffle?.statushh,
      hhDaysLong: editedRaffle?.hhDaysLong,
      statusMultiplicityByNft: editedRaffle?.statusMultiplicityByNft,
      hhDate: editedRaffle?.statushh == 1 ? moment().unix() : 0
    });

    console.log(res.data);

    fetchRaffles();
    
    toast("Розыгрыш успешно обновлен!", {type: "success"});
  }

  const [interID, setInterID] = useState(null as any);
  const [raffleTiming, setRaffleTiming] = useState("");
  const getRaffleTiming = () => {
    if (interID != null) {
      clearInterval(interID);
    }

    let inter = setInterval(() => {
      const dateStart = editedRaffle?.hhDate!;
      const daysLong = editedRaffle?.hhDaysLong! * 24 * 60 * 60;
      const now = moment().unix();

      if (now < (dateStart + daysLong)) {
        let diff = (dateStart + daysLong) - now;
        let end = moment((dateStart + daysLong) * 1000);
        let diffTime = (dateStart + daysLong) - now;
        let duration = moment.duration(diffTime * 1000, "milliseconds");
        
        let days: number = diff / 60 / 60 / 24;
        let hours = "";

        if (days > 0) {
          hours = `${duration.days()} д., ${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
          console.log(hours);
        } else {
          hours = "-- --";
        }

        setRaffleTiming(`${hours}`);
      } else {
        setRaffleTiming("-- -- --");
      }
    }, 1000);
    setInterID(inter);
  }

  useEffect(() => {
    if (interID != null) {
      clearInterval(interID);
    }

    if (editedRaffle != undefined && editedRaffle.status == 1) {
      getRaffleTiming();
    }
  }, [editedRaffle]);

  const addNewRaffleAction = async () => {
    if (editedRaffle?.statushh == 1) {
      editedRaffle.hhDate = moment().unix();
    }

    const res = await ApiService.raffles.createRaffle({...editedRaffle});
    console.log(res.data);

    setEditedRaffle(undefined);

    toast("Розыгрыш успешно добавлен!", {type: "success"});

    fetchRaffles();
  }

  const pauseRaffleAction = async () => {
    setIsPauseRaffleVisible(false);
    const res = await ApiService.raffles.updateRaffle(editedRaffle?.id!, {
      status: 0
    });
    fetchRaffles();
    toast("Розыгрыш успешно остановлен!", {type: "success"});
  }

  const finishRaffleAction = async () => {
    setIsFinishRaffleVisible(false);
    const res = await ApiService.raffles.finishRaffle(editedRaffle?.id!);
    fetchRaffles();
    toast("Розыгрыш успешно завершен!", {type: "success"});
  }

  const getMyTickets = () => {
    let lng = editedRaffle?.tickets!.filter(v => v.ticketValue == "1").length;
    return lng != undefined ? lng : 0;
  }
  const getMyTicketsBonused = () => {
    let lng = editedRaffle?.tickets!.filter(v => v.ticketValue == "2").length;
    return lng != undefined ? lng : 0;
  }

  useEffect(() => {
    fetchRaffles();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="page-header">
            Розыгрыши <small>Управление</small>
          </h1>

          <div className="card mb-4">
            <div className="p-4">
              <div className="row">
                <div className="col-12 col-md-5">
                
                  <h5 className="card__title">Список розыгрышей 
                    <span className="ct_action" style={{right: "16px"}} onClick={() => addNewRaffle()}><PlusOutlined /> <b style={{marginLeft: "6px", fontWeight: "500"}}>Создать</b></span>
                  </h5>
                  
                  <div className={styles.votings_list}>
                    {rafflesList.length == 0 ? <div className={styles.votings_list__empty}>
                      Розыгрыши отсутствуют! Создайте первое при помощи кнопки "<i className="far fa-plus"></i> Создать".
                    </div> : <></>}

                    {rafflesList.map(item => <div className={styles.votings_list__item} onClick={() => editRaffle(item)}>
                      <div className={styles.votings_list__item_status}><span style={{backgroundColor: item.status == 0 ? "#ffba00" : (item.status == 1 ? "#09bc09" : "#1d84ff")}}></span></div>
                      <div className={styles.votings_list__item_go}>
                        <i className="far fa-arrow-right"></i>
                      </div>
                      <div className={styles.votings_list__item_data}>
                        <div className={styles.votings_list__item_title}>{item.title}</div>
                        <div className={styles.votings_list__item_desc}>{item.description}</div>
                      </div>
                    </div>)}
                  </div>

                </div>

                {editedRaffle != undefined ? <div className="col-12 col-md-7">
                  {(editedRaffle.status == 1 || editedRaffle.status == 2) && !isNullOrUndefined(editedRaffle.tickets) ? <div className={styles.form_fields}>
                    <div className={styles.form_title}>Розыгрыш {editedRaffle.status == 1 ? "запущен" : "завершен"}</div>

                    <div className={styles.stats_counts}>
                      <div className={styles.stats_counts__item}>
                        <div>Кол-во купленных билетов</div>
                        <div>{editedRaffle.id == 6 ? getMyTickets() - 18 : getMyTickets()} <small style={{fontSize: "14px"}}>из {editedRaffle.maxTicketsCount}</small></div>
                      </div>
                      <div className={styles.stats_counts__item}>
                        <div>Сумма</div>
                        <div>{((editedRaffle.id == 6 ? getMyTickets() - 18 : getMyTickets()) * parseFloat(editedRaffle.ticketPrice!)).toFixed(2)} SOL</div>
                      </div>
                    </div>
                    <div className={styles.stats_counts}>
                      <div className={styles.stats_counts__item}>
                        <div>Кол-во бонусных билетов</div>
                        <div>{editedRaffle.id == 6 ? getMyTicketsBonused() + 18 : getMyTicketsBonused()} <small style={{fontSize: "14px"}}>на сумму {((editedRaffle.id == 6 ? getMyTicketsBonused() + 18 : getMyTicketsBonused()) * parseFloat(editedRaffle.ticketPrice!)).toFixed(2)} SOL</small></div>
                      </div>
                      {editedRaffle.status == 1 ? <div className={styles.stats_counts__item}>
                        <div>Время до завершения</div>
                        <div>{raffleTiming}</div>
                      </div> : <></>}
                    </div>

                    <div className={styles.scrollable}>
                      <a onClick={() => exportToXls()} className="btn btn-primary" style={{marginBottom: "16px"}}>Выгрузить в XLS</a>

                      <XTable
                        isLoading={false}
                        searchVisible={false}
                        searchAction={() => {}}
                        ths={ticketsOwnersThs} 
                        fields={ticketsOwnersTable} />
                    </div>

                    {editedRaffle.status == 1 ? <div className={styles.action}>
                      <button className="topracing-btn topracing-btn-green" style={{marginRight: "12px"}} onClick={() => setIsFinishRaffleVisible(true)}>Завершить и получить результаты</button>
                      <button className="topracing-btn topracing-btn-redd" onClick={() => setIsPauseRaffleVisible(true)}>Пауза</button>
                    </div> : <></>}
                  </div> : <></>}

                  <div className={styles.form_fields}>
                    <div className={styles.form_title}>{editOrAdd == 0 ? "Редактировать розыгрыш" : "Создание нового розыгрыша"}</div>
                    <div className={styles.form_fields_item}>
                      <div className={styles.form_fields_item__label}>Заголовок</div>
                      <input type="text" disabled={editedRaffle.status == 2} placeholder="Заголовок розыгрыша" name="title" value={editedRaffle.title} onChange={(event) => setEditedRaffle({...editedRaffle, title: event.target.value})} />
                    </div>
                    <div className={styles.form_fields_item}>
                      <div className={styles.form_fields_item__label}>Описание</div>
                      <textarea rows={5} disabled={editedRaffle.status == 2} placeholder="Описание розыгрыша" name="description" value={editedRaffle.description} onChange={(event) => setEditedRaffle({...editedRaffle, description: event.target.value})}></textarea>
                    </div>

                    <div className={styles.form_fields_item}>
                      <div className={styles.form_fields_item__label}>Изображение</div>
                      <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                          <i className="fas fa-image-polaroid"></i>
                        </p>
                        {editedRaffle.banner != "" ? <img src={editedRaffle.banner} id="banner_fill" /> : <></>}
                        <p className="ant-upload-text">Картинка</p>
                        <p className="ant-upload-hint">
                          нажмите или перенесите изображение в эту область
                        </p>
                      </Dragger>
                    </div>
                    
                    <div className={styles.flexed_fields}>
                      <div className={styles.form_fields_item}>
                        <div className={styles.form_fields_item__label}>Цена билета в SOL</div>
                        <input type="number" disabled={editedRaffle.status == 2} placeholder="Цена билета в SOL" name="ticketPrice" value={editedRaffle.ticketPrice} onChange={(event) => setEditedRaffle({...editedRaffle, ticketPrice: event.target.value})} />
                      </div>
                      <div className={styles.form_fields_item}>
                        <div className={styles.form_fields_item__label}>Кол-во билетов</div>
                        <input type="number" disabled={editedRaffle.status == 2} placeholder="Кол-во билетов" name="maxTicketsCount" value={editedRaffle.maxTicketsCount} onChange={(event) => setEditedRaffle({...editedRaffle, maxTicketsCount: parseInt(event.target.value)})} />
                      </div>
                      <div className={styles.form_fields_item}>
                        <div className={styles.form_fields_item__label}>Макс. кол-во на 1 кошелек</div>
                        <input type="number" disabled={editedRaffle.status == 2} placeholder="Макс. кол-во на 1 кошелек" name="maxWalletTicketsCount" value={editedRaffle.maxWalletTicketsCount} onChange={(event) => setEditedRaffle({...editedRaffle, maxWalletTicketsCount: parseInt(event.target.value)})} />
                      </div>
                    </div>
                    <div className={styles.flexed_fields}>
                      <div className={styles.form_fields_item} style={{width: "100%"}}>
                        <div className={styles.form_fields_item__label}>Кратность нфт на кошельке</div>
                        <input type="number" disabled={editedRaffle.status == 2} placeholder="Кратность нфт на кошельке" name="maxWalletTicketsCount" value={editedRaffle.multiplicityByNft} onChange={(event) => setEditedRaffle({...editedRaffle, multiplicityByNft: parseInt(event.target.value)})} />
                      </div>
                      <div className={styles.form_fields_item} style={{width: "100%"}}>
                        <div className={styles.form_fields_item__label}>Кол-во разыгрываемых WL Token</div>
                        <input type="number" disabled={editedRaffle.status == 2} placeholder="Кол-во разыгрываемых WLT" name="wlTokensCount" value={editedRaffle.wlTokensCount} onChange={(event) => setEditedRaffle({...editedRaffle, wlTokensCount: parseInt(event.target.value)})} />
                      </div>
                    </div>

                    <div className={styles.flexed_fields}>
                      <div className={styles.form_fields_item}>
                        <div className={styles.form_fields_item__label}>Ограничение по времени</div>
                        <Switch disabled={editedRaffle.status == 2} defaultChecked={editedRaffle.statushh == 1} checked={editedRaffle.statushh == 1} onChange={(event) => setEditedRaffle({...editedRaffle, statushh: event ? 1 : 0})} title="Статус розыгрыша" /> <span style={{marginLeft: "6px"}}>Статус <b>({editedRaffle.status == 1 ? "вкл" : "выкл"})</b></span>
                      </div>
                      {editedRaffle.statushh == 1 ? <div className={styles.form_fields_item} style={{width: "100%"}}>
                        <div className={styles.form_fields_item__label}>Кол-во дней до завершения</div>
                        <input type="number" disabled={editedRaffle.status == 2} placeholder="Кол-во дней до завершения" name="wlTokensCount" value={editedRaffle.hhDaysLong} onChange={(event) => setEditedRaffle({...editedRaffle, hhDaysLong: parseInt(event.target.value)})} />
                      </div> : <></>}
                    </div>

                    <div className={styles.flexed_fields}>
                      <div className={styles.form_fields_item}>
                        <div className={styles.form_fields_item__label}>Статус розыгрыша</div>
                        <Switch disabled={editedRaffle.status == 2} defaultChecked={editedRaffle.status == 1} checked={editedRaffle.status == 1} onChange={(event) => setEditedRaffle({...editedRaffle, status: event ? 1 : 0})} title="Статус розыгрыша" /> <span style={{marginLeft: "6px"}}>Статус <b>({editedRaffle.status == 1 ? "вкл" : "выкл"})</b></span>
                      </div>

                      <div className={styles.form_fields_item}>
                        <div className={styles.form_fields_item__label}>Добавление билетов за наличие NFT</div>
                        <Switch disabled={editedRaffle.status == 2} defaultChecked={editedRaffle.statusMultiplicityByNft == 1} checked={editedRaffle.statusMultiplicityByNft == 1} onChange={(event) => setEditedRaffle({...editedRaffle, statusMultiplicityByNft: event ? 1 : 0})} title="Статус розыгрыша" /> <span style={{marginLeft: "6px"}}>Статус <b>({editedRaffle.statusMultiplicityByNft == 1 ? "вкл" : "выкл"})</b></span>
                      </div>
                    </div>

                    {editedRaffle.status != 2 ? <div className={styles.action}>
                      {editOrAdd == 0 ? <button className="topracing-btn topracing-btn-red" onClick={() => saveRaffle()}>Сохранить</button> : <></>}
                      {editOrAdd == 0 ? <button className="topracing-btn topracing-btn-redd" style={{marginLeft: "16px"}} onClick={() => deleteRaffle(editedRaffle)}>Удалить</button> : <></>}
                      {editOrAdd == 1 ? <button className="topracing-btn topracing-btn-red" onClick={() => addNewRaffleAction()}>Создать</button> : <></>}
                    </div> : <></>}
                  </div>
                </div> : <></>}
              </div>
            </div>
          </div>
        </div>
      </div>


      <Modal
        visible={isAddRaffleVisible}
        title="Добавить розыгрыш"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите добавить Raffle{editedRaffle != undefined ? ` "${editedRaffle!.title}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => saveRaffleAction()}>Добавить</button>
          </div>
        </>
        }
        onClose={() => {setIsAddRaffleVisible(false)}}/>

      <Modal
        visible={isEditRaffleVisible}
        title="Обновить розыгрыш"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите обновить Raffle{editedRaffle != undefined ? ` "${editedRaffle!.title}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => saveRaffleAction()}>Обновить</button>
          </div>
        </>
        }
        onClose={() => {setIsEditRaffleVisible(false)}}/>

      <Modal
        visible={isDeleteRaffleVisible}
        title="Удалить розыгрыш"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите удалить Raffle{editedRaffle != undefined ? ` "${editedRaffle!.title}"` : ""}?
          </div>

          <div className="modal_action">
            <button onClick={() => deleteRaffleAction()}>Удалить</button>
          </div>
        </>
        }
        onClose={() => {setIsDeleteRaffleVisible(false)}}/>

      <Modal
        visible={isPauseRaffleVisible}
        title="Поставить розыгрыш на паузу"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите поставить Raffle{editedRaffle != undefined ? ` "${editedRaffle!.title}"` : ""} на паузу?
          </div>

          <div className="modal_action">
            <button onClick={() => pauseRaffleAction()}>Обновить</button>
          </div>
        </>
        }
        onClose={() => {setIsPauseRaffleVisible(false)}}/>

      <Modal
        visible={isFinishRaffleVisible}
        title="Завершить розыгрыш"
        maxWidth="520px"
        content={
        <>
          <div className="modal_text">
            Вы действительно хотите заверщить Raffle{editedRaffle != undefined ? ` "${editedRaffle!.title}"` : ""} и выявить победителей?
          </div>

          <div className="modal_action">
            <button onClick={() => finishRaffleAction()}>Завершить</button>
          </div>
        </>
        }
        onClose={() => {setIsFinishRaffleVisible(false)}}/>
    </>
  )
}

export default observer(Raffles)

/**
 {editedRaffle.status == 2 ? <div className={styles.raffle_winners}>
                      <div className={styles.raffle_winners__title}>Победители</div>
                      <div className={styles.raffle_winners__users}>
                        {editedRaffle.results?.map((result: any) => <div className={styles.raffle_winners__users_item}>
                          <div className={styles.raffle_winners__users_item__avatar} style={{backgroundImage: "url(" + result.ticket.ticket.author.avatar + ")"}}></div>
                          <div className={styles.raffle_winners__users_item__data}>
                            <div>{result.ticket.ticket.author.name}</div>
                            <div>Билет №{result.ticket._index} из {editedRaffle.tickets?.length}</div>
                            <div>{result.ticket.ticket.author.login}</div>
                          </div>
                        </div>)}
                      </div>
                    </div> : <></>}
 */