import { Badge, Layout, Row } from "antd";
import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import Avatar from "react-avatar";
import { useLocation, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { Context } from "..";
import useWindowDimensions from "../core/hooks/useWindowDimensions";

import styles from '../styles/Navbar.module.scss';
import HeaderMenuLink from "./MenuLink";

const Navbar: FC = () => {

  let resolved = useResolvedPath("/");
  let match = useMatch({ path: resolved.pathname, end: true });

  let { width, height } = useWindowDimensions();

  let { store } = useContext(Context);
  let navigate = useNavigate();

  return (
    <div id="header" className="app-header">

      <div className="mobile-toggler">
        <button type="button" className="menu-toggler" data-toggle="sidebar-mobile">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
      </div>

      <div className="brand">
        <a href="#" className="brand-logo">
          <img src="../../assets/images/logo_mbc_icon.png" style={{borderRadius: "12px", height: "20px", width: "20px"}} /> METABUILDERS
        </a>
      </div>

      <div className="menu">
        <form className="menu-search" method="POST" name="header_search_form">
          
        </form>
        <div className="menu-item dropdown">
          <a href="#" data-bs-toggle="dropdown" data-display="static" className="menu-link">
            <div className="menu-img">
              <Avatar 
                name={store.user.login}
                maxInitials={2}
                round={true}
                textSizeRatio={2.1}
                size="36px" />
            </div>
            <div className="menu-text"><span>{store.user.login}</span></div>
            <div className="menu-text logout" onClick={() => {store.logout(); navigate("/");}}><i className="fas fa-arrow-right-from-bracket"></i></div>
          </a>
          <div className="dropdown-menu dropdown-menu-right me-lg-3">
            <a className="dropdown-item d-flex align-items-center" onClick={() => {}}>Настройки <i className="fa fa-wrench fa-fw ms-auto text-dark text-opacity-50"></i></a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item d-flex align-items-center" onClick={() => {store.logout(); navigate("/");}}>Выйти <i className="fas fa-arrow-right-from-bracket fa-fw ms-auto text-dark text-opacity-50"></i></a>
          </div>
        </div>
      </div>

    </div>
  )
}

export default observer(Navbar)