import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { toast } from "react-toastify";
import XTable, { XTableCol } from "../../../components/XTable";
import { ApiService } from "../../../core/api";
import { useFetching } from "../../../core/hooks/useFetching";
import { IUser } from "../../../core/models/User";
import { isNullOrUndefined } from "../../../core/utils";

import styles from "../../../styles/TelegramBot.module.scss";

const TelegramBot: FC = () => {

  const [botMessageToAllText, setBotMessageToAllText] = useState("");
  const [botMessageToSpecifyText, setBotMessageToSpecifyText] = useState("");

  const sendBotMessageToSpecify = async () => {
    if (botMessageToSpecifyText != "") {
      if (selectedUsersList.length > 0) {
        let ids = "";
        selectedUsersList.forEach((v: any) => {
          ids += ids == "" ? v.socket_id : ";" + v.socket_id;
        });
        const res = await ApiService.mbc.botSendToSpecify(ids, botMessageToSpecifyText);
        toast("Сообщение успешно отправлено!", {type: "success"});
        setBotMessageToSpecifyText("");
      } else {
        toast("Выберите хотябы одного пользователя для отправки!", {type: "error"});
      }
    } else {
      toast("Введите текст сообщения!", {type: "error"});
    }
  }

  const sendBotMessageToAll = async () => {
    if (botMessageToAllText != "") {
      const res = await ApiService.mbc.botSendToAll(botMessageToAllText);
      toast("Сообщение успешно отправлено!", {type: "success"});
      setBotMessageToAllText("");
    } else {
      toast("Введите текст сообщения!", {type: "error"});
    }
  }

  const [searchText, setSearchText] = useState(""); // Wallet / Name / Telegram
  const [searchType, setSearchType] = useState("ALL"); // ALL / UNSUB / SUB / MODER / ADMIN 
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedUsersList, setSelectedUsersList] = useState([] as IUser[]);
  const [usersList, setUsersList] = useState([] as IUser[]);
  const [usersAllList, setUsersAllList] = useState([] as IUser[]);

  const [usersAll, setUsersAll] = useState([] as any[]); // MAIN / 
  const [usersTable, setUsersTable] = useState([] as XTableCol[][]); // MAIN / 
  const usersThs: string[] = ["ID", "Кошелек", "Имя", "Выбрать"];
  
  const [fetchUsers, isUsersLoading, errorUsers] = useFetching(async () => {
    const res = await ApiService.users.getUsers({
      page: pageIndex, limit: 6, search: searchText
    });

    setTotalPages(res.data.totalPages);
    setTotalUsers(res.data.total);
    setUsersAll(res.data.usersAll);

    let usrs: XTableCol[][] = [];
    let usrsAll: any[] = [...res.data.usersAll.filter((v: any) => !isNullOrUndefined(v.socket_id) && v.socket_id != "")];
    
    if (searchText != "") {
      usrsAll = usrsAll.filter((v: any) => v.name.toLowerCase().includes(searchText.toLowerCase()));
    }
    
    usrsAll.forEach((user: any) => {
      usrs.push([
        {text: user.id},
        {text: `${user.login.substr(0, 4)}...${user.login.substr(user.login.length - 4, user.login.length)}`},
        {text: user.name},
        {showActions: true, showActionsEdit: true, showActionsTrash: false, editAction: () => {
          setSelectedUsersList(
            (prev: any) => prev.findIndex((v: any) => v.id == user.id) === -1 ? prev = [...prev, user] : prev
          );
        }}
      ]);
    });

    setUsersTable(usrs);

    console.log(res.data);
    console.log(usrs);
  });

  const removeFromList = (item: IUser) => {
    setSelectedUsersList(prev => prev = [...prev.filter(v => v.id != item.id)]);
  }

  useEffect(() => {fetchUsers()}, [searchText, pageIndex]);

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="page-header">
            Telegram BOT <small>Управление</small>
          </h1>

          <div className="row">
            <div className="col-6">
              <div className="card mb-4">
                <div className="p-4">
                  <h5 className="card__title">Отправить общее сообщение</h5>
                  <div>
                    <div className="form-group mb-2">
                      <label className="form-label">Текст сообщения</label>
                      <div className="input-group">
                        <textarea rows={6} className="form-control" placeholder="Lorem ipsum..." value={botMessageToAllText} onChange={(event) => setBotMessageToAllText(event.target.value)}></textarea>
                      </div>
                    </div>
                    <button className="topracing-btn topracing-btn__big topracing-btn-red" onClick={() => sendBotMessageToAll()}>Отправить</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="card mb-4">
                <div className="p-4">
                  <h5 className="card__title">Отправить сообщение</h5>
                  <div>

                    <div className="form-group mb-2">
                      <label className="form-label">Текст сообщения</label>
                      <div className="input-group">
                        <textarea rows={6} className="form-control" placeholder="Lorem ipsum..." value={botMessageToSpecifyText} onChange={(event) => setBotMessageToSpecifyText(event.target.value)}></textarea>
                      </div>
                    </div>

                    <button className="topracing-btn topracing-btn__big topracing-btn-red" onClick={() => sendBotMessageToSpecify()}>Отправить</button>

                    <div className={styles.SelectedUsers__title}>Выбраные пользователи</div>
                    <div className={styles.SelectedUsers}>
                      {selectedUsersList.map(user => <div className={styles.SelectedUsers__item} onClick={() => removeFromList(user)}>
                        <div className={styles.SelectedUsers__item_avatar}><div style={{backgroundImage: "url(" + user.avatar + ")"}}></div></div>
                        <div className={styles.SelectedUsers__item_name}>{user.name}</div>
                      </div>)}
                    </div>

                    <div>
                      <div className="input-group mb-4">
                        <div className="flex-fill position-relative">
                          <div className="input-group">
                            <DebounceInput
                              className="form-control ps-35px"
                              minLength={2}
                              type="text"
                              placeholder="Поиск по кошельку, имени, телеграму..."
                              debounceTimeout={800}
                              onChange={event => setSearchText(event.target.value)}
                              />
                            <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0">
                              <i className="fa fa-search opacity-5"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <XTable
                        isLoading={isUsersLoading}
                        searchVisible={false}
                        searchAction={() => {}}
                        ths={usersThs} 
                        fields={usersTable} />
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(TelegramBot)