import { observer } from "mobx-react-lite"
import { FC } from "react"
import { ApiService } from "../core/api";
import { IUser } from "../core/models/User";
import {isNullOrUndefined} from "../core/utils";

export class XTableCol {
  text?: string = "";
  isImage?: boolean = false;
  isLink?: boolean = false;
  isBadge?: boolean = false;
  badgeState?: boolean = false;
  showActions?: boolean = true;
  showActionsTrash?: boolean = true;
  showActionsEdit?: boolean = false;
  trashAction?: any;
  editAction?: any;

  isUsers?: boolean = false;
  users?: IUser[];
}

interface XTableProps {
  isLoading: boolean;
  searchVisible: boolean;
  searchAction: any;
  ths: string[];
  fields: XTableCol[][];
}

const XTable: FC<XTableProps> = ({ isLoading, searchAction, searchVisible, ths, fields }) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover text-nowrap">
        <thead>
          <tr>
            {ths.map((th: string) => <th className="border-top-0 pt-0 pb-2">{th}</th>)}
          </tr>
        </thead>
        <tbody>
          {fields.map((v: XTableCol[]) => <tr>

            {v.map((col: XTableCol) => 
              <td className={col.showActions ? "align-middle table-actions" : "align-middle"}>
                {col.isImage ? <img style={{width: "24px"}} src={col.text} /> : <></> }
                {col.isLink ? <a href="#">{col.text}</a> : <></> }
                {col.isBadge ? col.badgeState ? <span className="badge bg-teal text-teal-800 bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    <span className="circled"></span> {col.text}
                  </span> : <span className="badge bg-red text-red-800 bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    <span className="circled"></span> {col.text}
                  </span> : <></> }
                {!col.isBadge && !col.isImage && !col.isLink && !col.showActions && !col.isUsers ? col.text : ""}
                {col.isUsers && col.users?.length == 2 ? <>
                  <div className="users_row">
                    <div className="users_row__item">
                      <div style={{backgroundImage: "url(" + col.users[0].avatar + ")"}}></div>
                      <div>{col.users[0]!.name}</div>
                      {!isNullOrUndefined(col.users[0]!.winner) && col.users[0]!.winner == true ? <div>WINNER</div> : <></>}
                    </div>
                    <div className="users_row__vs">VS</div>
                    <div className="users_row__item">
                      <div style={{backgroundImage: "url(" + col.users[1].avatar + ")"}}></div>
                      <div>{col.users[1]!.name}</div>
                      {!isNullOrUndefined(col.users[1]!.winner) && col.users[1]!.winner == true ? <div>WINNER</div> : <></>}
                    </div>
                  </div>
                </> : <></> }

                {col.showActions && col.showActionsEdit ? <span onClick={() => col.editAction()}><i className="fas fa-pen-to-square"></i></span> : <></>}
                {col.showActions && col.showActionsTrash ? <span onClick={() => col.trashAction()}><i className="fas fa-trash"></i></span> : <></>}
              </td>)}
          </tr>)}
        </tbody>
      </table>
    </div>
  )
}

export default observer(XTable)