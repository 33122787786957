import { FC, useContext, useEffect, useState } from "react";
import AppRouter from "./components/AppRouter";
import './App.scss';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { Context } from ".";
import { observer } from "mobx-react-lite";
import { Layout } from "antd";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";

const App: FC = () => {

  const { store } = useContext(Context);

  let navigate = useNavigate();
  let location = useLocation();
  let actualRouterPath = "/";

  const [topMenu, setTopMenu] = useState([
    {to: "/portfolio", title: "Портфель", icon: "fa fa-chart-pie"},
    {to: "/votings", title: "Голосования", icon: "fa fa-check-to-slot"},
    {to: "/raffles", title: "Розыгрыши", icon: "fa fa-trophy-star"},
    {to: "/tourneys", title: "Турниры", icon: "fa fa-bolt"},
    {to: "/mint", title: "Минт-Формы", icon: "fa fa-gears"},
    {to: "/whitepaper", title: "WhitePaper", icon: "fa fa-toilet-paper"},
    {to: "/users", title: "Пользователи", icon: "fa fa-users"},
    {to: "/payments", title: "Оплаты", icon: "fa fa-cart-shopping"},
    {to: "/drops", title: "Дропы", icon: "fa fa-rectangle-vertical-history"},
    {to: "/bot", title: "Telegram Бот", icon: "fa fa-message-bot"},
    {to: "/rumblebattle", title: "RumbleBattle", icon: "fa fa-swords"}
  ] as any[]);

  useEffect(() => {
    if (actualRouterPath != location.pathname) {
      actualRouterPath = location.pathname;
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    console.log(localStorage.getItem("token"));
    if (localStorage.getItem("token")) {
      store.applyAuth();
    } else {
      navigate("/signin");
    }
  }, []);

  return !store.isAuth ? (
    <>
      <AppRouter />
      <ToastContainer />
    </>
  ) : (
    <>
      <Layout>
        <Navbar />
        <Sidebar />
        <div id="content" className="app-content">
          <AppRouter />
        </div>
      </Layout>
      <ToastContainer />
    </>
  );
};

export default observer(App);

/**
 
          <div className="content_menu">
            {topMenu.map(item => <div onClick={() => navigate(item.to)} className={"content_menu__item" + (location.pathname == item.to ? " active" : "")}>
              <div><i className={item.icon}></i></div>
              <div>{item.title}</div>
            </div>)}
          </div>
 */