import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Context } from "../..";
import { ApiService } from "../../core/api";
import AuthService from "../../core/services/AuthService";

import styles from "../../styles/SignIn.module.scss";

const SingIn: FC = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("authed") == "1") {
      navigate("/");
    }
  });

  const [isLoading, setIsLoading] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const { store } = useContext(Context);

  const validate = async () => {
    setIsLoading(true);

    const auth = await ApiService.users.signIn({login, password});
    if (auth.data.state == 1) {
      localStorage.setItem("user", JSON.stringify(auth.data.user));
      localStorage.setItem("token", auth.data.token);
      localStorage.setItem("authed", "1");
      store.setAuth(true);
      store.setUser(auth.data.user);
      navigate("/");
    } else if (auth.data.state == 0) {
      toast.error(auth.data.message);
    }

    setIsLoading(false);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">

          <div className={styles.auth__form}>
            <div className={styles.auth__form_image}><img src="../../../assets/images/logo_mbc_icon.png" /></div>
            <div className={styles.auth__form_title}>ВХОД</div>

            <div className={styles.auth__form_fields}>
              <div className={styles.auth__form_fields_item}>
                <input type="text" placeholder="Email *" value={login} onChange={(event) => setLogin(event.target.value) } />
              </div>
              <div className={styles.auth__form_fields_item}>
                <input type="password" placeholder="Пароль *" value={password} onChange={(event) => setPassword(event.target.value) } />
              </div>
            </div>

            <div className={styles.auth__action}>
              <button disabled={isLoading} className="topracing-btn topracing-btn-red" onClick={() => validate()}>Войти</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default observer(SingIn)